import { LawTheme, LawThemes } from '@livv/models/firebaseRecords';

export const isLawTheme = (value: string): value is LawTheme => {
    return Object.values(LawThemes).includes(value as LawTheme);
};

export const extractTreeId = (path: string): string | null => {
    const decodedPath = decodeURIComponent(path);
    const [theme, treeId] = decodedPath.split('/');

    return theme && treeId && isLawTheme(theme) ? treeId : null;
};

export const extractThemeId = (path: string): LawTheme | null => {
    const decodedPath = decodeURIComponent(path);
    const [theme] = decodedPath.split('/');

    return theme && isLawTheme(theme) ? theme : null;
};
