import { createTheme, useMediaQuery } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';
import useLocalStorage from 'services/useLocalStorage';

const useTheme = () => {
    const computerIsDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [prefersDarkMode, setPrefersDarkMode] = useLocalStorage('dark-mode', computerIsDarkMode);

    const theme = createTheme({
        palette: {
            background: { default: '#F6F6F6', paper: 'white' },
            contrastThreshold: 3,
            error: { main: '#ff0000' },
            mode: 'light',
            primary: { dark: '#860000', light: '#f85046', main: '#be0c1d' },
            secondary: { main: '#aae3db' },
            tonalOffset: 0.2,
        },
    });

    const darkTheme = createTheme({
        palette: {
            background: { default: '#0B0B0B', paper: '#333333' },
            mode: 'dark',
            primary: { light: '#f85046', main: '#ee0f24' },
            secondary: { light: '#aae3db', main: '#79b1a9' },
        },
    });

    return [
        responsiveFontSizes(prefersDarkMode ? darkTheme : theme),
        { prefersDarkMode, setPrefersDarkMode },
    ] as const;
};

export default useTheme;
