import { CollectionName } from '@livv/models';
import { Keyword } from '@livv/models/firebaseRecords/keyword';
import { Tree } from '@livv/models/firebaseRecords/trees';
import { TreeData } from '@livv/models/treeDatas';
import { cleanTextQuotes } from './text';

export const getPathTitles = (path: Keyword['path'], tree: Tree | undefined): string[] => {
    const fallBack = ['[Thème non répertorié]'];
    if (!tree) return fallBack;

    try {
        const treeData = new TreeData(tree.content, tree.title);

        return [tree.title, ...treeData.getPathTitles(path)];
    } catch (err) {
        console.error(err);

        return fallBack;
    }
};

interface BlockData {
    text: string;
}
export const cleanBlocks = <T extends BlockData>(
    blocks: T[],
    cleanQuotes: boolean,
    isNote = false,
): T[] =>
    blocks.map((block) => ({
        ...block,
        text: cleanQuotes ? cleanTextQuotes(block.text) : block.text,
        ...(isNote ? { entityRanges: [] } : {}),
    }));

export const getTreesLabelByIds = (trees: Record<string, Tree>) =>
    Object.entries(trees).reduce(
        (treesIdsAndLabel, [id, { title }]) => ({
            ...treesIdsAndLabel,
            [id]: title,
        }),
        {},
    );

export const getThemeByTreeId = async (
    db: FirebaseFirestore.Firestore,
    treeId: string,
): Promise<string> => {
    const treeRef = (await db
        .collection(CollectionName.TREES)
        .doc(treeId)
        .get()) as FirebaseFirestore.DocumentSnapshot<Tree>;

    if (!treeRef.exists) {
        throw new Error(`Tree with id ${treeId} does not exist`);
    }

    const treeRecord = treeRef.data() as Tree;
    const { theme } = treeRecord;

    return theme;
};
