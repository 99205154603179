const stripHtmlTags = (s: string) => s.replace(/></g, '> <').replace(/<[^>]*>?/gm, '');

/**
 * Strip double spaces & diacritics, trim, and optionally convert to lower or upper case.
 * Diacritics are converted using approach described
 * in https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 * @param text string to normalize
 * @param toCase specify if the text should be lower or upper case. If not specified, the case is not changed.
 * @returns normalized string
 */
const normalize = (text: string, toCase?: 'lower' | 'upper') => {
    const normalizedTest = text
        .replace(/\s\s+/g, ' ')
        .replace(/[’]/g, "'")
        .normalize('NFD') // split an accented letter in the base letter and the accent like ("éô" in "e'o^")
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents ("e'o^" => "eo")
        .trim();

    switch (toCase) {
        case 'lower':
            return normalizedTest.toLowerCase();
        case 'upper':
            return normalizedTest.toUpperCase();
        default:
            return normalizedTest;
    }
};

const cleanTextQuotes = (initialText: string): string => {
    // we allow string beginning or spaces before the quotes
    // we allow end of string, spaces and punctuation after the quotes
    const afterSymbols = '[\\s.?!;,]';
    const replacementPattern = '$1“$2”$3';

    const quoteRegex = (openingQuote: string, endingQuote?: string) => {
        const pattern = `(^|\\s)${openingQuote}(\\s?.+?\\s?)${
            endingQuote || openingQuote
        }(${afterSymbols}|$)`;

        return new RegExp(pattern, 'gi');
    };

    return (
        initialText
            // french quotes
            .replace(quoteRegex('«', '»'), replacementPattern)
            // double quotes
            .replace(quoteRegex('"'), replacementPattern)
            // single quotes
            .replace(quoteRegex("'"), replacementPattern)
    );
};

const removeBlankSpacesFromString = (text: string): string =>
    text
        .trim()
        .replace(/\s{2,}/g, ' ')
        .replace(' ,', ',');

function removeBlankSpacesOverload(data: string): string;
function removeBlankSpacesOverload(data: unknown[]): unknown[];
function removeBlankSpacesOverload<T>(data: T): T;
function removeBlankSpacesOverload(data: unknown) {
    if (typeof data === 'string') {
        return removeBlankSpacesFromString(data);
    }
    if (Array.isArray(data)) {
        return data.map((element) => removeBlankSpacesOverload(element));
    }
    if (data && typeof data === 'object' && !(data instanceof Date)) {
        return Object.entries(data).reduce(
            (cleaned, [key, value]) => ({ ...cleaned, [key]: removeBlankSpacesOverload(value) }),
            {},
        );
    }

    return data;
}

/**
 * Converts a given string to uppercase and replaces spaces with underscores.
 *
 * This function first normalizes the input string to uppercase using the `normalize` function.
 * Then it splits the string into words and joins them with underscores.
 *
 * @param {string} text - The input string to be converted.
 * @returns {string} The converted string in uppercase with spaces replaced by underscores.
 */
const toUpperCaseWithUnderscores = (text: string): string => {
    return normalize(text, 'upper').split(' ').join('_');
};

export {
    cleanTextQuotes,
    removeBlankSpacesOverload as removeBlankSpaces,
    normalize,
    stripHtmlTags,
    toUpperCaseWithUnderscores,
};
