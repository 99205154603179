const SEPARATOR = '/';

export class Path {
    ids: string[];

    constructor(path: string[]) {
        this.ids = path;
    }

    empty() {
        return !this.ids.length;
    }

    popRoot() {
        if (this.empty()) throw new Error('No root without path');

        return [this.ids[0], new Path(this.ids.slice(1))] as const;
    }

    nodeId() {
        if (this.empty()) throw new Error('No node id for an empty path');

        return this.ids[this.ids.length - 1];
    }

    parentPath() {
        if (this.empty()) throw new Error('No parent path without a path');

        return new Path(this.ids.slice(0, -1));
    }

    childPath(nodeId: string) {
        return new Path([...this.ids, nodeId]);
    }

    descendantPath(relativePath: Path) {
        return new Path([...this.ids, ...relativePath.ids]);
    }

    toString() {
        return this.ids.join(SEPARATOR);
    }
}
