import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import { isProductionEnv } from '@livv/utils/helpers';
import * as Sentry from '@sentry/browser';
import './index.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        enabled: process.env.NODE_ENV === 'production',
        environment: isProductionEnv ? 'production' : 'development',
        release: process.env.REACT_APP_SENTRY_RELEASE,
    });
}

const container = document.getElementById('root');

// As per documentation https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
