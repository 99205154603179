export enum SearchableCollection {
    ANALYSES = 'analyses',
    ARTICLES = 'articles',
    CODE_ARTICLES = 'codeArticles',
    DECISIONS = 'decisions',
    GLOSSARY_TERMS = 'glossaryTerms',
    LAW_TEXTS = 'laws',
    PUBLICATION_SHEETS = 'publicationSheets',
    STATISTICS = 'statistics',
}
