import { Dialog, Backdrop, Box, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { FC } from 'react';
import GridLoader from 'react-spinners/GridLoader';

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    const { value } = props;

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ mt: 4, width: '100%' }}>
                <LinearProgress color="primary" variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, mt: 2 }}>
                {value > 0 && (
                    <Typography color="primary.main" fontSize={20} fontWeight={700} variant="body1">
                        {`${Math.round(value)}%`}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

const Loader = () => {
    const theme = useTheme();

    return <GridLoader color={theme.palette.primary.main} size={20} />;
};

export default Loader;

export const LoadingPage: FC = () => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            width: '100vw',
        }}
    >
        <Loader />
    </Box>
);

export const CenteredLoader = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ display: 'inline-block' }}>
            <Loader />
        </Box>
    </Box>
);

export const LoadingDialog: FC = () => (
    <Dialog
        BackdropProps={{ sx: { bgcolor: (th) => `${th.palette.grey[100]}B3` } }}
        PaperComponent={({ children }) => <div>{children}</div>}
        aria-labelledby="simple-dialog-title"
        open
    >
        <Loader />
    </Dialog>
);

const overlaySx: SxProps<Theme> = {
    alignItems: 'center',
    bgcolor: (theme) => `${theme.palette.background.default}99`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 99,
};

// TODO merge with LoadingBackdrop
export const LoadingOverlay: FC = () => (
    <Box sx={overlaySx}>
        <Loader />
    </Box>
);

interface LoadingBackdropProps {
    absolute?: boolean;
    progressValue?: number;
}
// "absolute" will contain the backdrop in parent component (instead of fullscreen)
// if it has position: relative
export const LoadingBackdrop: FC<LoadingBackdropProps> = ({ absolute, progressValue }) => (
    <Backdrop
        sx={[
            {
                bgcolor: (th) => `${th.palette.background.default}B3`,
                position: `${absolute ? 'absolute' : 'fixed'}`,
                zIndex: 1201,
            },
        ]}
        open
    >
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '200px',
                width: '50%',
            }}
        >
            <Loader />
            {progressValue !== undefined && progressValue > 0 && (
                <LinearProgressWithLabel value={progressValue} />
            )}
        </Box>
    </Backdrop>
);
