import { DecisionRaw } from '@livv/models';
import { getUTCDate } from '../date';
import { removeBlankSpaces } from '../text';
import cleanJuridictionRoom from './cleanJuridictionRoom';
import filterFullText from './filterFullText';

const cleanDecisionData = (decisionData: DecisionRaw): DecisionRaw => {
    const { fullText = '', ...data } = decisionData;

    const dataWithoutBlankSpaces = removeBlankSpaces(data);
    const {
        analysisInDecisionUpdatedAt,
        biblio,
        city,
        createdAt,
        decisionStatus,
        decisionType,
        defendant,
        isFormatVerified,
        juridictionName,
        juridictionRoom,
        juridictionType,
        jury,
        language,
        lawScopes,
        legislation,
        note,
        number,
        performedAt,
        plaintiff,
        publicationStatus,
        result,
        sameCase,
        summary,
        title,
        uniqueIds,
    } = dataWithoutBlankSpaces;
    const cleanData: DecisionRaw = {
        analysisInDecisionUpdatedAt,
        biblio,
        city,
        createdAt,
        decisionStatus,
        decisionType,
        defendant,
        isFormatVerified,
        juridictionName,
        uniqueIds,
        juridictionRoom: cleanJuridictionRoom(juridictionRoom),
        juridictionType,
        jury: jury.map(({ names, position }) => ({ names, position })),
        language,
        lawScopes,
        legislation,
        note,
        number,
        performedAt: getUTCDate(performedAt),
        plaintiff,
        publicationStatus,
        sameCase,
        summary,
        title,
        updatedAt: new Date(),
    };
    if (result) {
        cleanData.result = result;
    }

    return {
        fullText: filterFullText(fullText),
        ...cleanData,
    };
};

export default cleanDecisionData;
