import { Timestamp } from '@firebase/firestore-types';
import { LanguageUppercase } from '../languages';
import { LawScope } from '../lawScope';
// TODO: Reorganize folder structure to avoid circular dependency
// eslint-disable-next-line import/no-cycle
import { TreeDataNode } from '../treeDatas';

// fantom type, shouldn't appear anywhere, only using the two convert function below
export type TreeContent = {
    particularTreeContent: true;
};

export const TreeContent = {
    fromRaw(treeData: TreeDataNode[]) {
        return JSON.stringify(treeData) as unknown as TreeContent;
    },
    toRaw(treeContent: TreeContent) {
        let jsonString: string;

        try {
            // Attempt to treat treeContent as a JSON string
            jsonString = treeContent.toString();
            JSON.parse(jsonString);
        } catch (error) {
            // If treeContent is not a valid JSON string, serialize it
            jsonString = JSON.stringify(treeContent);
        }

        try {
            return JSON.parse(jsonString) as TreeDataNode[];
        } catch (parseError) {
            console.error('Failed to parse treeContent', parseError);

            return [];
        }
    },
};

export type LawTheme =
    | 'economic-law'
    | 'commercial-law'
    | 'practical-works'
    | 'procedural-law'
    | 'other';

export const LawThemes = {
    ECONOMIC_LAW: 'economic-law',
    COMMERCIAL_LAW: 'commercial-law',
    PROCEDURAL_LAW: 'procedural-law',
    PRACTICAL_WORKS: 'practical-works',
    OTHER: 'other',
} as const;

export interface Tree {
    authors: string[];
    category: string;
    content: TreeContent;
    collection: string;
    displayedUpdateDate: Timestamp;
    dummyLevel?: boolean;
    editors: string[];
    isKeyword?: boolean;
    isPublished: boolean;
    language: LanguageUppercase;
    lawScopes: LawScope[];
    summary: string;
    title: string;
    theme: LawTheme;
    updatedAt: Timestamp;
}

// dummyLevel: set to "true" for book trees whose first title level is "part"
// otherwise first level is "book"

export enum TreeCategory {
    BUSINESS_EURO = 'Droit européen des affaires',
    CODE = 'Législation',
    COMPETITION = 'Concurrence',
    CONSUMER_AFFAIRS = 'Consommation',
    DISTRIBUTION = 'Distribution',
    OTHER = 'Autre',
}

export interface TreeWithId extends Tree {
    id: string;
}
