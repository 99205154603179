import { endOfDay, format, setHours, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 * Convert JS Date to string in the given format
 * @param date - Date to format
 * @param formatStr - Format to use
 * @param customFormat - Custom format to use if format is 'custom',
 * see date-fns doc for full list of format options: https://date-fns.org/v2.16.1/docs/format
 * @returns - Formatted date
 */
export const formatDate = (
    date: Date,
    formatStr?: 'litteral' | 'numeric' | 'custom',
    customFormat?: string,
): string => {
    switch (formatStr) {
        case 'litteral':
            return format(date, 'MMMM dd, yyyy');
        case 'numeric':
            return format(date, 'MM/dd/yyyy');
        case 'custom':
            return format(date, customFormat || 'MM/dd/yyyy');
        default:
            return format(date, 'MMMM dd, yyyy');
    }
};

/** Transform local date to UTC date at midnight */
export const getUTCDate = (localDate: Date) => {
    return startOfDay(localDate);
};

/** Transform midnight UTC date to local date at midday */
export const getLocalDate = (UTCDate: Date) => {
    return setHours(UTCDate, 12);
};

/**
 * Convert a date to a date representing the end of the day in a given timezone.
 * @param date Date to convert
 * @param timeZone Timezone to use, default is 'Europe/Paris'
 * @returns Date representing the end of the day in the given timezone
 */
export const getEndOfDayInTimezone = (date: Date | number, timeZone = 'Europe/Paris'): Date => {
    const endOfDayDate = endOfDay(date);

    return zonedTimeToUtc(endOfDayDate, timeZone);
};
