import { UserPrivileges } from '@livv/models';

type NeededPrivileges = Pick<UserPrivileges, 'isPaidCustomer' | 'trialEndDate' | 'role'>;

const isAllowedToConsumePaidContent = (
    { isPaidCustomer, role, trialEndDate }: NeededPrivileges,
    isEmailVerified: boolean,
): boolean =>
    isEmailVerified &&
    (role === 'admin' || isPaidCustomer || (trialEndDate ? trialEndDate > Date.now() : false));

export { isAllowedToConsumePaidContent };
