export enum Gender {
    FEMININE = 'F',
    FEMININE_PLURAL = 'FP',
    MASCULINE = 'M',
    MASCULINE_PLURAL = 'MP',
}

export const getGenderLabel = (gender: Gender | string): string => {
    switch (gender) {
        case Gender.FEMININE:
            return 'Féminin singulier';
        case Gender.FEMININE_PLURAL:
            return 'Féminin pluriel';
        case Gender.MASCULINE:
            return 'Masculin singulier';
        case Gender.MASCULINE_PLURAL:
            return 'Masculin pluriel';
        default:
            return 'Rien';
    }
};
