import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import commonEn from 'locales/en/common.json';
// import modelTypeEn from 'locales/en/modelType.json';
import { initReactI18next } from 'react-i18next';
import commonFr from 'locales/fr/common.json';
import modelTypeFr from 'locales/fr/modelType.json';

const ns = ['common', 'modelType'];

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        cleanCode: true,
        compatibilityJSON: 'v3',
        debug: process.env.NODE_ENV === 'development',
        defaultNS: ns[0],
        fallbackLng: 'fr',
        lng: 'fr',
        ns,
        resources: {
            // TODO: Understand how trads are loaded (en are used even with fr selected)
            // en: { common: commonEn, modelType: modelTypeEn },
            fr: { common: commonFr, modelType: modelTypeFr },
        },
    });

export default i18n;
