import { Box, SxProps } from '@mui/material';
import { EmailAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar, { Logo } from 'components/AppBar';
import { auth } from 'services/firebase';
import 'firebaseui/dist/firebaseui.css';
import './index.css';

const formSx: SxProps = {
    alignItems: 'center',
    display: 'flex',
    height: '80vh',
    justifyContent: 'center',
};

const Login = () => {
    const { search } = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const signInSuccessUrl = searchParams.get('redirect_url') || '/';

        const ui = new firebaseui.auth.AuthUI(auth);

        const uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult() {
                    return true;
                },
            },
            signInOptions: [
                {
                    disableSignUp: {
                        adminEmail: 'support@livv.eu',
                        helpLink: 'https://app.livv.eu/signup',
                        status: true,
                    },
                    provider: EmailAuthProvider.PROVIDER_ID,
                    signInMethod: 'password',
                },
            ],
            signInSuccessUrl,
        };

        ui.start('#firebaseui-auth-container', uiConfig);
    }, [search]);

    return (
        <>
            <AppBar>
                <Box sx={{ m: 'auto' }}>
                    <Logo />
                </Box>
            </AppBar>
            <Box sx={formSx}>
                <div id="firebaseui-auth-container" />
            </Box>
        </>
    );
};

export default Login;
