import { ModelType } from '../modelType';

export enum NodeEnum {
    SOURCE = 'source',
    TARGET = 'target',
}

export interface CitationNode {
    id: string;
    title: string;
    type: ModelType;
}

export interface Citation {
    source: CitationNode;
    target: CitationNode;
}
