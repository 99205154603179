export enum LawScope {
    FR = 'fr',
    EU = 'eu',
}

const lawscopeNameValue = [
    {
        label: 'Droit français',
        value: LawScope.FR,
    },
    {
        label: 'Droit européen',
        value: LawScope.EU,
    },
];
const lawscopeNameValueMap = new Map<LawScope | null, string>();
lawscopeNameValue.forEach(({ label, value }) => lawscopeNameValueMap.set(value, label));
lawscopeNameValueMap.set(null, '');

export { lawscopeNameValue, lawscopeNameValueMap };
