async function* paginate(
    collection: FirebaseFirestore.CollectionReference | FirebaseFirestore.Query,
    pageSize: number,
) {
    const first = await collection.limit(pageSize).get();
    yield first.docs;

    // do not try to set the next batch cursor if first is empty
    if (first.docs.length > 0) {
        let last = first.docs[first.docs.length - 1];
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const pageSnapshot = await collection.startAfter(last).limit(pageSize).get();
            if (pageSnapshot.empty) {
                return;
            }
            yield pageSnapshot.docs;
            last = pageSnapshot.docs[pageSnapshot.docs.length - 1];
        }
    }
}

export default paginate;
