export interface NumberDetails {
    prefix: string;
    year: number;
    number: number;
    suffix: string;
}

export interface LastLawLexNumbers {
    Articles: NumberDetails & { prefix: 'Art'; suffix: '' };
    Glossary: NumberDetails & { prefix: 'Glo'; suffix: '' };
    JuriBaseAdmin: NumberDetails & { prefix: 'LawLex'; suffix: 'JBA' };
    JuriBaseAnalysis: NumberDetails & { prefix: 'LawLex'; suffix: 'JBN' };
    JuriBaseJurisprudence: NumberDetails & { prefix: 'LawLex'; suffix: 'JBJ' };
    JuriBaseLegis: NumberDetails & { prefix: 'LawLex'; suffix: 'JBL' };
    Statistics: NumberDetails & { prefix: 'Stat'; suffix: '' };
}

export enum LastLawLexKey {
    ANALYSIS = 'JuriBaseAnalysis',
    ARTICLES = 'Articles',
    GLOSSARY = 'Glossary',
    JURI_BASE_JURISPRUDENCE = 'JuriBaseJurisprudence',
    JURI_BASE_LEGIS = 'JuriBaseLegis',
    STATISTICS = 'Statistics',
}

export const decisionIdRegex = /lawlex\d{12}jbj/i;
