import { diff_match_patch as diffMatchPatch } from 'diff-match-patch';
import { stripHtmlTags } from './text';

export { DIFF_DELETE, DIFF_EQUAL, DIFF_INSERT } from 'diff-match-patch';

export const diffColors = {
    deleteBg: '#F6C6C8',
    deleteText: '#A90D05',
    insertBg: '#C6FADD',
    insertText: '#5B7B6D',
};

export type Diff = [number, string];

const NEW_LINE = `
`;

export const preprocessHtml = (texteHtml: string): string =>
    stripHtmlTags(texteHtml.replace(/<\/p>/g, NEW_LINE).replace(/<br\s?\/?>/g, NEW_LINE)).trim();

export const computeDiff = (before: string, after: string): Diff[] => {
    // eslint-disable-next-line new-cap
    const dmp = new diffMatchPatch();
    const diffs = dmp.diff_main(before, after);
    dmp.diff_cleanupSemantic(diffs);

    return diffs;
};

/**
 * Given an array of diffs; group them by paragraphs and
 * return an array of paragraph-diffs
 */
export const groupParagraphs = (diffs: Diff[]): Diff[][] => {
    const paragraphsDiffs: Diff[][] = [];
    let currentParagraph: Diff[] = [];
    for (const diff of diffs) {
        const [diffMode, text] = diff;
        const chunks = text.split(/\n+/g);
        if (chunks.length === 1) {
            currentParagraph.push(diff);
        } else {
            for (let i = 1; i < chunks.length; i++) {
                currentParagraph.push([diffMode, chunks[i - 1]]);
                paragraphsDiffs.push(currentParagraph);
                currentParagraph = i === chunks.length - 1 ? [[diffMode, chunks[i]]] : [];
            }
        }
    }
    if (currentParagraph.length) paragraphsDiffs.push(currentParagraph);

    return paragraphsDiffs;
};
