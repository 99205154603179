import { AppBar as MuiAppBar, SxProps, useTheme } from '@mui/material';
import { AppBarProps } from '@mui/material/AppBar';
import { FC } from 'react';
import logo from '../logo.svg';
import whiteLogo from '../logo_white.svg';
import { sxs } from 'utils/mui';

export const Logo = () => {
    const { palette } = useTheme();

    return (
        <img
            alt="Logo"
            src={palette.mode === 'dark' ? whiteLogo : logo}
            style={{ marginLeft: 10, maxHeight: 40 }}
        />
    );
};

export const HEADER_HEIGHT = '57px';

const appBarSx: SxProps = {
    bgcolor: 'background.paper',
    borderBottom: 1,
    borderColor: '#DDD',
    boxShadow: 'none',
    color: 'text.primary',
    height: HEADER_HEIGHT,
    position: 'sticky',
    zIndex: 'appBar',
};

const AppBar: FC<AppBarProps> = ({ sx, ...props }) => (
    <MuiAppBar sx={sxs([sx, appBarSx])} {...props} />
);

export default AppBar;
