import { FormControlLabel, Switch } from '@mui/material';
import { createContext, useContext } from 'react';

const defaultContext = {
    themePreferences: {
        prefersDarkMode: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setPrefersDarkMode: (value: boolean) => {},
    },
};

const PreferencesContext = createContext(defaultContext);

export const PreferencesProvider = PreferencesContext.Provider;

export const DarkModeSwitch = () => {
    const {
        themePreferences: { prefersDarkMode, setPrefersDarkMode },
    } = useContext(PreferencesContext);

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={prefersDarkMode}
                    color="primary"
                    onChange={(e) => setPrefersDarkMode(e.target.checked)}
                />
            }
            label="Dark"
            sx={{ m: 0 }}
        />
    );
};
