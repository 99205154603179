import { Analysis, AnalysisWithId, VALIDATION_STATUSES } from '@livv/models';

/** Returns published and validated analyses */
export const getPublicAnalyses = (
    analyses: Analysis[] | AnalysisWithId[],
): Analysis[] | AnalysisWithId[] =>
    analyses.filter(
        ({ isPublished, validationStatus }) =>
            isPublished && validationStatus === VALIDATION_STATUSES.VALIDATED,
    );
