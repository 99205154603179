import { QueryDocumentSnapshot, QuerySnapshot } from '@firebase/firestore-types';
import { CollectionName, DeletedDecision } from '@livv/models';
import { Firestore as AdminFirestore } from 'firebase-admin/firestore';

const FIRESTORE_MAX_DISJUNCTIONS = 30;

/** Returns an empty array if decision is not deleted or an array of deleted imported decisions if found */
export const findDeletedDecisionsByUniqueIds =
    (db: AdminFirestore) =>
    async (uniqueIds: string[]): Promise<QueryDocumentSnapshot<DeletedDecision>[]> => {
        if (uniqueIds.length > FIRESTORE_MAX_DISJUNCTIONS) {
            console.warn(`Decision has too many unique ids. Slicing to 30 first ids.`);
        }

        return (
            (await db
                .collection(CollectionName.DELETED_DECISIONS)
                .where(
                    'uniqueIds',
                    'array-contains-any',
                    uniqueIds.slice(0, FIRESTORE_MAX_DISJUNCTIONS),
                )
                .get()) as unknown as QuerySnapshot<DeletedDecision>
        ).docs;
    };
