/**
 *  This function has 2 roles :
    - filter all external links in fullText
    - avoid having an empty fullText
 */
const filterFullText = (fullText: string): string => {
    const linkReg = /<a[^>]*href="[^#][^>]*>/g;
    const matches = fullText.match(linkReg);
    let newText = fullText;
    try {
        // try to remove only external links
        if (matches?.length) {
            matches.forEach((match) => {
                const index = newText.indexOf(match);
                if (index > 0) {
                    const before = newText.slice(0, index);
                    const after = newText.slice(index + match.length);
                    newText = before + after.replace('</a>', '');
                }
            });
        }
    } catch (e) {
        // if error, remove all links
        console.error('Problem in filterFullText');

        return newText.replace(/<a[^>]*>/g, '').replace(/<\/a>/g, '') || '<p></p>';
    }

    return newText || '<p></p>';
};

export default filterFullText;
