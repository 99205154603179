import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { ReactComponent as SVG } from 'assets/undraw_server_down_s4lk.svg';
import SVGPage from 'components/SVGPage';

const Page500 = () => (
    <SVGPage>
        <Typography color="textSecondary" variant="h2" gutterBottom>
            Quelque chose s’est mal passé
        </Typography>
        <Typography color="textSecondary" gutterBottom>
            Un message vient d&apos;être envoyé aux développeurs pour qu&apos;ils corrigent.
        </Typography>
        <Typography color="textSecondary" gutterBottom>
            Vous pouvez <a href="/">retournez à l&apos;accueil</a> pour reprendre votre navigation.
        </Typography>
        <SVG />
    </SVGPage>
);

export class ErrorBoundary extends React.Component {
    constructor(props: Readonly<PropsWithChildren>) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { hasError } = this.state;
        if (hasError) {
            return <Page500 />;
        }

        const { children } = this.props as PropsWithChildren;

        return children;
    }
}

export default Page500;
