import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import firebaseConfig from '../../firebase-config.json';

const firebaseApp = initializeApp(firebaseConfig.result.sdkConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west1');

if (process.env.REACT_APP_EMULATED_FUNCTIONS) {
    connectFunctionsEmulator(functions, 'localhost', 5556);
}

export { auth, db, functions, storage };
