import { normalize } from '../text';

const formatNumberSuffix = (numberAndSuffix: string): string =>
    numberAndSuffix
        .toLowerCase()
        .replace(/\b1(ere|er|e)\b/g, '1re') // Uniformize number suffix: 1ere, 1er, 1e => 1re
        .replace(/\d(eme)\b/g, (captured) => captured.replace('eme', 'e')); // Uniformize number suffix: 2eme, 3eme... => 2e, 3e...

const cleanJuridictionRoom = (dirty: string): string => {
    if (!dirty) return dirty;

    return normalize(dirty, 'upper')
        .replace(',', '')
        .replace(/\d+[a-zA-Z]+/g, formatNumberSuffix) // Format number suffix
        .replace(/1re (BUREAU|CABINET|PRESIDENT)/g, (captured) => captured.replace('re', 'er')); // Few exceptions with er
};

export default cleanJuridictionRoom;
