import { Timestamp } from '@firebase/firestore-types';
import { LawScope } from '../lawScope';
import { Keyword } from './keyword';

interface AnalysisCore {
    content: string;
    decisionId: string;
    isFlash: boolean;
    isPublished: boolean;
    keyword: Keyword;
    keywordEn: Keyword;
    language: string;
    lawScopes: LawScope[];
    position: number;
    translation?: string;
    validationStatus: ValidationStatus;
}

export const VALIDATION_STATUSES = {
    TO_REVIEW: 'toReview',
    REVIEWED: 'reviewed',
    VALIDATED: 'validated',
} as const;

export type ValidationStatus = (typeof VALIDATION_STATUSES)[keyof typeof VALIDATION_STATUSES];

export const statusTranslation: Record<ValidationStatus, string> = {
    toReview: 'À relire',
    reviewed: 'Relue',
    validated: 'Validée',
};

export const statusColor: Record<ValidationStatus, 'warning' | 'info' | 'success'> = {
    toReview: 'warning', // refers to theme
    reviewed: 'info',
    validated: 'success',
};

export interface AnalysisRaw extends AnalysisCore {
    createdAt: Date;
    updatedAt: Date;
}

export interface Analysis extends AnalysisCore {
    createdAt: Timestamp;
    updatedAt: Timestamp;
}

export interface ExtendedAnalysis extends Analysis {
    title: string;
    performedAt: Timestamp;
}

export interface AnalysisWithId extends Analysis {
    id: string;
}

export interface AnalysisPrint {
    content: string;
    contentEn?: string;
    pathTitles: string[];
    pathTitlesEn?: string[];
}

export type AnalysisCollection<T extends Analysis> = [T[], boolean, string];
