import { Bucket } from '@google-cloud/storage';

// TODO: Replace unknown type by the right Bucket type.
// Bucket from firebase-admin is not exposed, but is slightly different than Bucket from @google-cloud/storage
const getFile =
    (storageBucket: unknown) =>
    async (fileName: string): Promise<string | undefined> => {
        try {
            const file = (storageBucket as Bucket).file(fileName);
            const response = await file.download();

            return response.toString();
        } catch (e) {
            console.error(e);

            return undefined;
        }
    };

export default getFile;
