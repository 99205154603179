import { CollectionName } from '@livv/models/collections';
import { ModelType } from '@livv/models/modelType';

export const getCollectionNameFromModelType = (
    type: ModelType,
    documentId: string,
): CollectionName => {
    switch (type) {
        case ModelType.ANALYSIS:
            return CollectionName.ANALYSES;
        case ModelType.ARTICLE:
            return CollectionName.ARTICLES;
        case ModelType.DECISION:
            return CollectionName.DECISIONS;
        case ModelType.GLOSSARY_TERM:
            return CollectionName.GLOSSARY_TERMS;
        case ModelType.LAW_TEXT:
            return documentId.startsWith('LEGIARTI')
                ? CollectionName.CODE_ARTICLES
                : CollectionName.LAW_TEXTS;
        case ModelType.PUBLICATION_SHEET:
            return CollectionName.PUBLICATION_SHEETS;
        case ModelType.STATISTIC:
            return CollectionName.STATISTICS;
        default:
            throw Error(`Collection not found for model type ${type}.`);
    }
};

export const getModelTypeFromCollectionName = (collection: CollectionName): ModelType => {
    switch (collection) {
        case CollectionName.ANALYSES:
            return ModelType.ANALYSIS;
        case CollectionName.ARTICLES:
            return ModelType.ARTICLE;
        case CollectionName.DECISIONS:
            return ModelType.DECISION;
        case CollectionName.GLOSSARY_TERMS:
            return ModelType.GLOSSARY_TERM;
        case CollectionName.CODE_ARTICLES:
        case CollectionName.LAW_TEXTS:
            return ModelType.LAW_TEXT;
        case CollectionName.PUBLICATION_SHEETS:
            return ModelType.PUBLICATION_SHEET;
        case CollectionName.STATISTIC_ENTRIES:
        case CollectionName.STATISTICS:
            return ModelType.STATISTIC;
        default:
            throw Error(`Model not found for collection ${collection}.`);
    }
};
