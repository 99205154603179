import { Timestamp } from '@firebase/firestore-types';
import { PublicationSheet } from '../publicationSheets';

export interface RevisionCore {
    id: string;
    from: PublicationSheet | false;
    to: Pick<PublicationSheet, 'title' | 'titleNote' | 'content' | 'isPublished'>;
    treeId: string;
    path: string[];
    context?: string[];
}

export interface Revision extends RevisionCore {
    updatedAt: Timestamp;
}

export interface RevisionRaw extends RevisionCore {
    updatedAt: Date;
}

export interface EditRevision extends RevisionCore {
    from: PublicationSheet;
}

export interface NewRevision extends RevisionCore {
    from: false;
}

export const isNewRevision = (revision: RevisionCore): boolean => !revision.from;
