import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React from 'react';
import AppBar, { Logo } from './components/AppBar';
import SVGPage from './components/SVGPage';
import { signout } from './services/authentification';
import { ReactComponent as SVG } from './assets/undraw_security_o890.svg';

const CenterLogo = styled.div`
    margin: auto;
`;

const email = 'support@livv.eu';

const Anonymous: React.FC = () => (
    <>
        <AppBar>
            <CenterLogo>
                <Logo />
            </CenterLogo>
        </AppBar>
        <SVGPage>
            <Typography color="textSecondary" variant="h2" gutterBottom>
                Accès interdit
            </Typography>
            <Typography color="textSecondary" gutterBottom>
                {"N'hésitez pas à nous contacter pour plus d'information "}
                <a href={`mailto:${email}`}>{email}</a>
            </Typography>
            <Typography color="textSecondary" gutterBottom>
                Si vous pensez avoir un accès, vous pouvez essayer avec un autre compte :{' '}
                <a href="/login" onClick={signout}>
                    déconnexion
                </a>
            </Typography>
            <SVG />
        </SVGPage>
    </>
);

export default Anonymous;
