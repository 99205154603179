/**
 * Interfaces description https://piste.gouv.fr/index.php?option=com_apiportal&view=apitester&usage=api&apitab=tests&apiName=JUDILIBRE&apiId=b6d2f389-c3ec-4eb3-9075-bc24d0783781&managerId=2&type=rest&apiVersion=1.0.0&Itemid=402&swaggerVersion=2.0&lang=fr
 * Use credentials from Livv 1password to login to https://piste.gouv.fr/ and access this documentation
 */

/**
 * @description Objet décrivant un lien vers un fichier pouvant être associé à une décision.
 */
interface FileLink {
    /** @description Identifiant du fichier lié. */
    id: number;

    /** @description URL du fichier lié. */
    URL: string;

    /**
     * @description Type du fichier lié (par exemple : communiqué, note explicative, traduction, rapport, avis de l'avocat général, etc.).
     */
    type: string;

    /** @description Intitulé du fichier lié. */
    label: string;
}

/**
 * Taxonomy of the court of appeal:
 * @description
 * ca_agen - "Cour d'appel d'Agen"
 * @description
 * ca_aix_provence - "Cour d'appel d'Aix-en-Provence"
 * @description
 * ca_amiens - "Cour d'appel d'Amiens"
 * @description
 * ca_angers - "Cour d'appel d'Angers"
 * @description
 * ca_basse_terre - "Cour d'appel de Basse-Terre"
 * @description
 * ca_bastia - "Cour d'appel de Bastia"
 * @description
 * ca_besancon - "Cour d'appel de Besançon"
 * @description
 * ca_bordeaux - "Cour d'appel de Bordeaux"
 * @description
 * ca_bourges - "Cour d'appel de Bourges"
 * @description
 * ca_caen - "Cour d'appel de Caen"
 * @description
 * ca_cayenne - "Cour d'appel de Cayenne"
 * @description
 * ca_chambery - "Cour d'appel de Chambéry"
 * @description
 * ca_colmar - "Cour d'appel de Colmar"
 * @description
 * ca_dijon - "Cour d'appel de Dijon"
 * @description
 * ca_douai - "Cour d'appel de Douai"
 * @description
 * ca_fort_de_france - "Cour d'appel de Fort-de-France"
 * @description
 * ca_grenoble - "Cour d'appel de Grenoble"
 * @description
 * ca_limoges - "Cour d'appel de Limoges"
 * @description
 * ca_lyon - "Cour d'appel de Lyon"
 * @description
 * ca_metz - "Cour d'appel de Metz"
 * @description
 * ca_montpellier - "Cour d'appel de Montpellier"
 * @description
 * ca_nancy - "Cour d'appel de Nancy"
 * @description
 * ca_nimes - "Cour d'appel de Nîmes"
 * @description
 * ca_noumea - "Cour d'appel de Nouméa"
 * @description
 * ca_orleans - "Cour d'appel d'Orléans"
 * @description
 * ca_papeete - "Cour d'appel de Papeete"
 * @description
 * ca_paris - "Cour d'appel de Paris"
 * @description
 * ca_pau - "Cour d'appel de Pau"
 * @description
 * ca_poitiers - "Cour d'appel de Poitiers"
 * @description
 * ca_reims - "Cour d'appel de Reims"
 * @description
 * ca_rennes - "Cour d'appel de Rennes"
 * @description
 * ca_riom - "Cour d'appel de Riom"
 * @description
 * ca_rouen - "Cour d'appel de Rouen"
 * @description
 * ca_st_denis_reunion - "Cour d'appel de Saint-Denis de la Réunion"
 * @description
 * ca_toulouse - "Cour d'appel de Toulouse"
 * @description
 * ca_versailles - "Cour d'appel de Versailles"
 */
type Location =
    | 'ca_agen'
    | 'ca_aix_provence'
    | 'ca_amiens'
    | 'ca_angers'
    | 'ca_basse_terre'
    | 'ca_bastia'
    | 'ca_besancon'
    | 'ca_bordeaux'
    | 'ca_bourges'
    | 'ca_caen'
    | 'ca_cayenne'
    | 'ca_chambery'
    | 'ca_colmar'
    | 'ca_dijon'
    | 'ca_douai'
    | 'ca_fort_de_france'
    | 'ca_grenoble'
    | 'ca_limoges'
    | 'ca_lyon'
    | 'ca_metz'
    | 'ca_montpellier'
    | 'ca_nancy'
    | 'ca_nimes'
    | 'ca_noumea'
    | 'ca_orleans'
    | 'ca_papeete'
    | 'ca_paris'
    | 'ca_pau'
    | 'ca_poitiers'
    | 'ca_reims'
    | 'ca_rennes'
    | 'ca_riom'
    | 'ca_rouen'
    | 'ca_st_denis_reunion'
    | 'ca_toulouse'
    | 'ca_versailles';

/** Values can be obtained from /taxonomy endpoint */
type Jurisdiction = 'cc' | 'ca' | 'tj';

/**
 * Represents a chamber of court.
 * @description
 * 'pl' - Assemblée plénière
 * @description
 * 'mi' - Chambre mixte
 * @description
 * 'civ1' - Première chambre civile
 * @description
 * 'civ2' - Deuxième chambre civile
 * @description
 * 'civ3' - Troisième chambre civile
 * @description
 * 'comm' - Chambre commerciale financière et économique
 * @description
 * 'soc' - Chambre sociale
 * @description
 * 'cr' - Chambre criminelle
 * @description
 * 'creun' - Chambres réunies
 * @description
 * 'ordo' - Première présidence (Ordonnance)
 * @description
 * 'allciv' - Toutes les chambres civiles
 * @description
 * 'other' - Autre
 */
type ChamberCC =
    | 'pl'
    | 'mi'
    | 'civ1'
    | 'civ2'
    | 'civ3'
    | 'comm'
    | 'soc'
    | 'cr'
    | 'creun'
    | 'ordo'
    | 'allciv'
    | 'other';

/**
 * Taxonomy of the court chambers:
 * @description
 * b - "Publié au Bulletin"
 * @description
 * r - "Publié au Rapport"
 * @description
 * l - "Publié aux Lettres de chambre"
 * @description
 * c - "Communiqué"
 * @description
 * n - "Non publié"
 */
export type Publication = 'b' | 'r' | 'l' | 'c' | 'n';

/**
 * Possible CC decision types:
 * @description
 * 'arret' - Arrêt. Scope: CC, CA
 * @description
 * 'avis' - Demande d'avis. Scope: CC
 * @description
 * 'qpc' - Question prioritaire de constitutionnalité (QPC). Scope: CC
 * @description
 * 'ordonnance' - Ordonnance. Scope: CC, CA
 * @description
 * 'other' - Autre. Scope: CC, CA
 * @description
 * One value 'saisie' was specified in API doc but never encountered in the data
 * and it is not present in this type.
 */
export type TypeCC = 'arret' | 'avis' | 'qpc' | 'ordonnance' | 'other';

/**
 * Possible CA decision types:
 * @description
 * 'arret' - Arrêt. Scope: CC, CA
 * @description
 * 'ordonnance' - Ordonnance. Scope: CC, CA
 * @description
 * 'other' - Autre. Scope: CC, CA
 * @description
 */
export type TypeCA = 'arret' | 'ordonnance' | 'other';

/**
 * Classification of verdicts:
 * @description
 * cassation - "Cassation".
 * @description
 * rejet - "Rejet".
 * @description
 * annulation - "Annulation"
 * @description
 * avis - "Avis"
 * @description
 * decheance - "Déchéance"
 * @description
 * designation - "Désignation de juridiction"
 * @description
 * irrecevabilite - "Irrecevabilité"
 * @description
 * nonlieu - "Non-lieu à statuer"
 * @description
 * qpc - "QPC renvoi"
 * @description
 * qpcother - "QPC autres"
 * @description
 * rabat - "Rabat"
 * @description
 * [reglement] - "Règlement des juges" is removed because from type because it does not appear in any new decision.
 * @description
 * renvoi - "Renvoi"
 * @description
 * other - "Autre". Scope: CA et CC
 */
type Solution =
    | 'cassation'
    | 'rejet'
    | 'annulation'
    | 'avis'
    | 'decheance'
    | 'designation'
    | 'irrecevabilite'
    | 'nonlieu'
    | 'qpc'
    | 'qpcother'
    | 'rabat'
    | 'renvoi'
    | 'other';

/**
 * @description Décrit la version courte d'un décision, telle qu'elle apparaît en tant qu'item de résultat de recherche.
 * Rappel : le texte intégral et les zones qu'il contient ne sont pas inclus dans les résultats de la recherche. La récupération d'une décision complète (incluant les zones) repose sur le point d'entrée `GET /decision`.
 */
interface DecisionShort {
    /** @description Identifiant de la décision. */
    id: string;

    /**
     * @description Clé de la juridiction. Par défaut, utiliser `GET /taxonomy?id=jurisdiction&key={jurisdiction}` pour récupérer l'intitulé complet de celle-ci. Si la requête utilise `resolve_references=true`, alors cette propriété contient l'intitulé complet de celle-ci.
     */
    jurisdiction: Jurisdiction;

    /**
     * @description Clé de la chambre. Par défaut, utiliser `GET /taxonomy?id=chamber&context_value={jurisdiction}&key={chamber}` pour récupérer l'intitulé complet de celle-ci. Si la requête utilise `resolve_references=true`, alors cette propriété contient l'intitulé complet de celle-ci.
     * Modified to optional, sometimes absent for Cour d'appel decisions. Also for CA decisions, the value is not always aligned with the value in decision text
     */
    chamber?: ChamberCC | string;

    /** @description Numéro de pourvoi de la décision. */
    number: string;

    /** @description All decision numbers, not in the official API documentation probably to an error */
    numbers: string[];

    /** @description Code ECLI de la décision. */
    ecli: string;

    /**
     * @description Clé de la formation. Par défaut, utiliser `GET /taxonomy?id=formation&context_value={jurisdiction}&key={formation}` pour récupérer l'intitulé complet de celle-ci. Si la requête utilise `resolve_references=true`, alors cette propriété contient l'intitulé complet de celle-ci.
     */
    formation: string;

    /**
     * @description Clés du niveau de publication. Par défaut, utiliser `GET /taxonomy?id=publication&context_value={jurisdiction}&key={publication}` pour récupérer le nom de chaque clé. Si la requête utilise `resolve_references=true`, alors cette propriété contient l'intitulé complet de celui-ci.
     */
    publication: Publication[];

    /** @description Date de création de la décision, au format ISO-8601 (par exemple 2021-05-13). */
    decision_date: string;

    /**
     * @description Clé du type de décision. Par défaut, utiliser `GET /taxonomy?id=type&context_value={jurisdiction}&key={type}` pour récupérer l'intitulé complet de celui-ci. Si la requête utilise `resolve_references=true`, alors cette propriété contient l'intitulé complet de celui-ci.
     */
    type?: TypeCC | TypeCA;

    /**
     * @description Clé de la solution. Par défaut, utiliser `GET /taxonomy?id=solution&context_value={jurisdiction}&key={solution}` pour récupérer l'intitulé complet de celle-ci. Si la requête utilise `resolve_references=true`, alors cette propriété contient l'intitulé complet de celle-ci.
     */
    solution: Solution;

    /**
     * @description Intitulé complet de la solution (si celle-ci n'est pas normalisée et comprise dans la taxonomie, la propriété solution valant alors `other`).
     * Often present in decisions of CA.
     */
    solution_alt?: string;

    /** @description Sommaire (texte brut). */
    summary?: string;

    /** @description Numéro de publication au bulletin. */
    bulletin?: string;

    /**
     * @description Liste des fichiers associés à la décision, chaque document étant représenté par un objet de type `fileLink` où `type` contient le type de document (communiqué, note explicative, traduction, rapport, avis de l'avocat général, etc.), `label` son intitulé et `URL` contient le lien vers celui-ci.
     */
    files?: FileLink[];

    /** @description Liste des matières (ou éléments de titrage) par ordre de maillons (texte brut). */
    themes?: string[];

    /** Name of the 'Cour d'Appel' with the city. E.g Cour d'appel de Paris */
    location?: Location;
}

type ContestedDecision = Pick<
    DecisionShort,
    'id' | 'jurisdiction' | 'chamber' | 'number' | 'solution'
> & { title?: string };

/**
 * @description Décrit l'objet contenant une décision intégrale.
 */
export interface DecisionFull extends DecisionShort {
    /**
     * @description Objet de type `zone` énumérant les différentes zones détectées dans le texte intégral de la décision. Chaque zone contient une liste d'objets `{ start, end }` indiquant respectivement l'indice de début et de fin des caractères (relativement au texte intégral) contenus dans chaque segment de la zone (une zone pouvant contenir plusieurs segments).
     * In the taxonomy it corresponds to the 'field' id. Probably and error of naming in the API.
     */
    zones?: Zone;

    /** @description Texte intégral et pseudonymisé de la décision (texte brut). */
    text: string;

    /** @description Texte intégral de la décision dans lequel les correspondances avec la recherche saisie (dans le cas où le paramètre `query` a été transmis à la requêtes) sont délimitées par des balises `<em>`. */
    text_highlight?: string;

    /** @description Code NAC de la décision. */
    nac?: string;

    /** @description Date de dernière mise à jour de la décision. */
    update_date?: string;

    /** @description Liste des textes appliqués par la décision, chaque texte étant représenté par un objet de type `attachment` où `label` contient l'intitulé du texte et `URL` contient le lien vers celui-ci. */
    visa?: TextLink[];

    /** @description Liste des rapprochements de jurisprudence, chaque rapprochement étant représenté par un objet de type `attachment` où `label` contient son numéro de pourvoi, `description` son court texte descriptif, `theme` la liste de ses matières (ou éléments de titrage) et `URL` le lien vers celui-ci. */
    rapprochements?: DecisionLink[];

    /** @description Lorsque la propriété `to_be_deleted` est définie et vaut `true`, alors la décision correspondante doit être supprimée des archives ou des bases de données du réutilisateur. Cette propriété peut apparaître dans le cadre d'un export des décisions mises à jour (`/export?date_type=update`). */
    to_be_deleted?: boolean;

    /** Optional object containing a decision that is contested by current decision. Not documented in the official API doc. */
    contested?: ContestedDecision;

    /** Non-documented field that probably hold the original source of the decision. E.g { source: 'jurica' } */
    source?: string;
}

/**
 * @title Type d'objet `decisionLink`.
 * @description Objet décrivant un lien vers une décision.
 */
interface DecisionLink {
    /** @description Identifiant de la décision. */
    id: string;

    /** @description URL de la décision. */
    URL: string;

    /** @description Description de la décision. */
    description: string;

    /** @description Liste des thèmes (ou éléments de titrage) associés à la décision. */
    theme: string[];

    /** @description Numéro de pourvoi de la décision. */
    number: string;
}

/**
 * @title Type d'objet `textLink`.
 * @description Objet décrivant un lien vers un texte appliqué.
 */
interface TextLink {
    /** @description Identifiant du texte. */
    id: number;

    /** @description URL du texte. */
    URL: string;

    /** @description Intitulé du texte. */
    title: string;
}

/**
 * @interface ZoneSegment
 * @description Décrit un segment d'une zone en tant qu'objet `{ start, end }` indiquant respectivement l'indice de début et de fin des caractères (relativement au texte intégral) contenus dans le segment de la zone considérée.
 */
interface ZoneSegment {
    /**
     * @description Indice de début du segment.
     * @type {number}
     */
    start: number;

    /**
     * @description Indice de fin du segment.
     * @type {number}
     */
    end: number;
}

/**
 * @description Une liste d'objets de type `ZoneSegment`, chacun contenant un objet `{ start, end }` indiquant respectivement l'indice de début et de fin des caractères (relativement au texte intégral) contenus dans chaque segment de la zone.
 * @type {ZoneSegment} Offsets in characters of the segments in the text
 */
interface ZoneSegment {
    end: number;
    start: number;
}

/**
 * @description Identifiant de la zone :\n* `introduction` : introduction de la décision\n* `expose` : exposé du litige\n* `moyens` : moyens\n* `motivations` : motivations\n* `dispositif` : dispositifs\n* `annexes` : moyens annexés.
 */
interface Zone {
    annexes?: ZoneSegment[];
    dispositif?: ZoneSegment[];
    expose?: ZoneSegment[];
    introduction?: ZoneSegment[];
    motivations?: ZoneSegment[];
    moyens?: ZoneSegment[];
}

/**
 * Type d'objet `searchHighlight`.
 * Décrit un fragment de résultat contenant une correspondance avec la recherche saisie.
 */
interface SearchHighlight {
    /**
     * Nom du champ (`text` pour le texte entier) ou de la zone contenant le segment (par exemple : `expose`, `dispositif`, `summary`, etc.). Il peut y avoir plusieurs segments par zone.
     */
    key: string;

    /**
     * Liste des segments de texte contenant une ou plusieurs correspondances.
     */
    value: string[];
}

/**
 * Type d'objet `searchResult`.
 * Décrit un élément de la liste de résultats de recherche.
 * Rappel : le texte intégral et les zones qu'il contient ne sont pas inclus dans les résultats de la recherche. La récupération d'une décision complète (incluant les zones) repose sur le point d'entrée `GET /decision`.
 */
export interface SearchResult extends DecisionShort {
    /**
     * Score de la décision (pertinence) float entre 0 et 1 (max_score).
     */
    score: number;

    /**
     * Liste des segments de la décision ayant des correspondances avec la requête saisie, les correspondances étant délimitées par des balises `<em>`. Chaque segment est un objet de type `searchHighlight`.
     */
    highlights?: SearchHighlight[];
}

/**
 * Décrit l'objet contenant les paramètres d'une requête de recherche.
 * Type d'objet `searchQuery`.
 * Correspond à payload envoyé avec la requêtes GET vers le path `/search`
 */
export interface SearchQuery {
    /**
     * Chaîne de caractères correspondant à la recherche.
     * Une recherche avec un paramètre `query` vide ou manquant est ignorée et retourne un résultat vide.
     */
    query?: string;

    /**
     * Liste des champs, métadonnées ou zones de contenu ciblés par la recherche.
     * Une recherche avec un paramètre `field` vide ou manquant est appliquée à l'intégralité de la décision
     * mais va exclure les métadonnées.
     * Seems to be equivalent to Zone values.
     */
    field?: string[];

    /**
     * Opérateur logique reliant les multiples termes que le paramètre `query` peut contenir.
     * (`or` par défaut, `and` ou `exact` – dans ce dernier cas le moteur recherchera exactement le contenu du paramètre `query`).
     */
    operator?: string;

    /**
     * Filtre les résultats suivant la natures des décisions.
     * Une recherche avec un paramètre `type` vide ou manquant retourne des décisions de toutes natures.
     */
    type?: string[];

    /**
     * Filtre les résultats suivant la matière (nomenclature de la Cour de cassation) relative aux décisions.
     * Une recherche avec un paramètre `theme` vide ou manquant retourne des décisions relatives à toutes les matières.
     */
    theme?: string[];

    /**
     * Filtre les résultats suivant la chambre relative aux décisions.
     * Une recherche avec un paramètre `chamber` vide ou manquant retourne des décisions relatives à toutes les chambres.
     */
    chamber?: string[];

    /**
     * Filtre les résultats suivant la formation relative aux décisions.
     * Une recherche avec un paramètre `formation` vide ou manquant retourne des décisions relatives à toutes les formations.
     */
    formation?: string[];

    /**
     * Filtre les résultats suivant la juridiction relative aux décisions.
     * Une recherche avec un paramètre `jurisdiction` vide ou manquant retourne des décisions relatives à toutes les juridictions.
     */
    jurisdiction?: string[];

    /**
     * Filtre les résultats suivant la commission relative aux décisions.
     * Une recherche avec un paramètre `committee` vide ou manquant retourne des décisions relatives à toutes les commissions.
     * IG: seems not to be implemented
     */
    committee?: string[];

    /**
     * Filtre les résultats suivant le niveau de publication des décisions.
     * Une recherche avec un paramètre `publication` vide ou manquant retourne des décisions de n'importe quel niveau de publication.
     */
    publication?: string[];

    /**
     * Filtre les résultats suivant le type de solution des décisions.
     * Une recherche avec un paramètre `solution` vide retourne des décisions ayant n'importe quel type de solution.
     */
    solution?: string[];

    /**
     * Combiné avec le paramètre `date_end`, permet de restreindre les résultats à un intervalle de dates, au format ISO-8601 (par exemple 2021-05-13).
     * Correspond à la date de publication de la décision.
     */
    date_start?: string;

    /**
     * Combiné avec le paramètre `date_start`, permet de restreindre les résultats à un intervalle de dates, au format ISO-8601 (par exemple 2021-05-13).
     * Correspond à la date de publication de la décision.
     */
    date_end?: string;

    /**
     * Permet de choisir la valeur suivant laquelle les résultats sont triés (`score` pour un tri par pertinence, `scorepub` pour un tri par pertinence et niveau de publication et `date` pour un tri par date, vaut `scorepub` par défaut).
     */
    sort?: string;

    /**
     * Permet de choisir l'ordre du tri (`asc` pour un tri ascendant ou `desc` pour un tri descendant, vaut `desc` par défaut).
     */
    order?: string;

    /**
     * Permet de déterminer le nombre de résultats retournés par page (50 maximum, vaut 10 par défaut).
     */
    page_size?: number;

    /**
     * Permet de déterminer le numéro de la page de résultats à retourner (la première page valant `0`).
     */
    page?: number;

    /**
     * Lorsque ce paramètre vaut `true`, le résultat de la requête contiendra, pour chaque information retournée par défaut sous forme de clé, l'intitulé complet de celle-ci (vaut `false` par défaut).
     */
    resolve_references?: boolean;
}

/**
 * Type d'objet `searchPage`.
 * Décrit une page de résultats de recherche.
 * Correspond à la réponse de la requête GET vers le path `/search`
 */
export interface SearchPage {
    /**
     * Indice de la page courante de résultat (la première page valant 0).
     */
    page: number;

    /**
     * Nombre de résultats retournés par page (10 par défaut).
     */
    page_size: number;

    /**
     * Objet contenant les paramètres de la requête originelle.
     * Reference to searchQuery
     */
    query: SearchQuery;

    /**
     * Nombre total de décisions retournées par la requête.
     */
    total: number;

    /**
     * URL de la page de résultats suivante (propriété absente si la page courante est la dernière).
     */
    next_page?: string;

    /**
     * URL de la page de résultats précédente (propriété absente si la page courante est la première).
     */
    previous_page?: string;

    /**
     * Temps d'exécution de la requête (en millisecondes).
     */
    took: number;

    /**
     * Score maximal obtenu sur l'ensemble des résultats.
     */
    max_score: number;

    /**
     * Liste des résultats retournés, chaque résultat étant un objet de type `searchResult`.
     * La propriété est absente si la recherche n'a retourné aucun résultat.
     * Reference to searchResult
     */
    results?: SearchResult[];

    /**
     * Indicates whether API relaxed search parameters to return some results.
     * Usually related to the query operator. E.g. if specified { "operator": "and" }
     * and no results are found, the API will try to return some results
     * by relaxing "operator" to default "or" and return { "operator": "or" }
     * in the response SearchPage object "query" property.
     * NB. This property is not documented in the official API documentation.
     */
    relaxed?: boolean;
}

/**
 * Permet de récupérer le contenu intégral d'une décision.
 * Connaissant l'identifiant unique d'une décision, le point d'entrée `GET /decision` permet d'en récupérer le contenu intégral
 * (structuré, mais sans mise en forme), à savoir :
 * Certaines des informations ne sont retournées que sous forme de clé ou d'identifiant numérique (juridiction, chambre, niveau de publication, etc.).
 * Il convient dès lors d'utiliser le point d'entrée `GET /taxonomy` pour en récupérer l'intitulé complet, ou d'effectuer la requête en utilisant
 * le paramètre `resolve_references=true`.
 * Retourne DecisionFull
 */
export interface GetDecisionRequest {
    /**
     * Identifiant de la décision à récupérer.
     */
    id: string;

    /**
     * Lorsque ce paramètre vaut `true`, le résultat de la requête contiendra, pour chaque information retournée par défaut sous forme de clé,
     * l'intitulé complet de celle-ci (vaut `false` par défaut).
     */
    resolve_references?: boolean;

    /**
     * Chaîne de caractères correspondant à la recherche. Ce paramètre est utilisé pour surligner en retour, dans le texte intégral de la décision,
     * les termes correspondant avec la recherche initiale (ces termes étant délimitées par des balises `<em>`).
     */
    query?: string;

    /**
     * Opérateur logique reliant les multiples termes que le paramètre `query` peut contenir (`or` par défaut, `and` ou `exact` – dans ce dernier cas
     * le moteur recherchera exactement le contenu du paramètre `query`).
     */
    operator?: 'or' | 'and' | 'exact';
}

/**
 * Objet décrivant l'état de disponibilité du service.
 */
export interface Health {
    /**
     * État de disponibilité du service.
     */
    status: 'disponible' | 'indisponible';
}

/**
 * Objet décrivant un élément de la taxonomie.
 */
interface TaxonItem {
    /**
     * Clé de l'élément.
     */
    key?: string; // TODO: supected that description is erroneous and it's just [key: string]: string;

    /**
     * Intitulé complet de l'élément.
     */
    value?: string;
}

/**
 * Objet décrivant le résultat d'une requête `/taxonomy`.
 */
export interface TaxonResult {
    /**
     * Identifiant de l'entrée de taxonomie interrogée.
     */
    id?: string;

    /**
     * Clé dont on veut récupérer l'intitulé complet (pour une requête avec `key`).
     */
    key?: string;

    /**
     * Liste des résultats retournés, chaque résultat étant un objet contenant un couple clé/valeur
     * (appel par `id` seul), ou seulement une clé ou une valeur (appel par `key` ou `value`).
     */
    result?: TaxonItem;

    /**
     * Intitulé complet dont on veut récupérer la clé (pour une requête avec `value`).
     */
    value?: string;
}

/**
 * Permet de récupérer les listes des termes employés par le processus de recherche.
 * L'API publique propose la récupération des listes des termes (sous la forme d'un couple clé/valeur) constituants les différents critères et filtres pris en compte par le processus de recherche et les données qu'il restitue, notamment :
 * * La liste des types de décision (`type`) ;
 * * La liste des juridictions dont le système intègre les décisions (`jurisdiction`) ;
 * * La liste des chambres (`chamber`) ;
 * * La liste des formations (`formation`) ;
 * * La liste des commissions (`committee`) ;
 * * La liste des niveaux de publication (`publication`) ;
 * * La liste des matières (`theme`) ;
 * * La liste des solutions (`solution`) ;
 * * La liste des champs et des zones de contenu des décisions pouvant être ciblés par la recherche (`field`) ;
 * * La liste des zones de contenu des décisions (`zones`) ;
 * * etc.
 * La publication de cette taxonomie permet principalement au prestataire chargé de l'implémentation du frontend (ainsi qu'à certains réutilisateurs avancés) d'automatiser la constitution des formulaires de recherche et l'enrichissement des résultats retournés.
 */
export interface GetTaxonomyRequest {
    /**
     * Identifiant de l'entrée de taxonomie à interroger (`type`, `jurisdiction`, `chamber`, etc. - les valeurs disponibles sont accessibles via `GET /taxonomy` sans paramètre).
     */
    id: string;

    /**
     * Clé du terme dont on veut récupérer l'intitulé complet (le paramètre `id` est alors requis), par exemple : la requête `GET /taxonomy?id=jurisdiction&key=cc` retournera `Cour de cassation`.
     */
    key?: string;

    /**
     * Intitulé complet du terme dont on veut récupérer la clé (le paramètre `id` est alors requis), par exemple : la requête `GET /taxonomy?id=jurisdiction&value=cour%20de%20cassation` retournera `cc`.
     */
    value?: string;

    /**
     * Valeur pouvant être requise pour contextualiser certaines listes (par exemple, la liste des chambres qui n’a de sens que dans le contexte d’une juridiction – ainsi, pour obtenir la liste des chambres de la Cour de cassation : `GET /taxonomy?id=chamber&context_value=cc`).
     */
    context_value?: string;
}

/**
 * Permet d'effectuer un export par lot de décisions de justice.
 * Destiné aux utilisateurs désirant procéder à leur propre indexation et mise à disposition du contenu,
 * ce point d'entrée leur permet de récupérer des lots de décisions complètes suivant des paramètres et critères simples.
 * Retourne ExportBatch
 */
export interface ExportQuery {
    /**
     * Filtre les résultats suivant la natures des décisions.
     * Un export avec un paramètre `type` vide ou manquant retourne des décisions de toutes natures.
     */
    type?: string[];

    /**
     * Filtre les résultats suivant la matière (nomenclature de la Cour de cassation) relative aux décisions.
     * Un export avec un paramètre `theme` vide ou manquant retourne des décisions relatives à toutes les matières.
     */
    theme?: string[];

    /**
     * Filtre les résultats suivant la chambre relative aux décisions.
     * Un export avec un paramètre `chamber` vide ou manquant retourne des décisions relatives à toutes les chambres.
     */
    chamber?: string[];

    /**
     * Filtre les résultats suivant la formation relative aux décisions.
     * Un export avec un paramètre `formation` vide ou manquant retourne des décisions relatives à toutes les formations.
     */
    formation?: string[];

    /**
     * Filtre les résultats suivant la juridiction relative aux décisions.
     * Un export avec un paramètre `jurisdiction` vide ou manquant retourne des décisions relatives à toutes les juridictions.
     */
    jurisdiction?: string[];

    /**
     * Filtre les résultats suivant la commission relative aux décisions.
     * Un export avec un paramètre `committee` vide ou manquant retourne des décisions relatives à toutes les commissions.
     */
    committee?: string[];

    /**
     * Filtre les résultats suivant le niveau de publication des décisions.
     * Un export avec un paramètre `publication` vide ou manquant retourne des décisions de n'importe quel niveau de publication.
     */
    publication?: string[];

    /**
     * Filtre les résultats suivant le type de solution des décisions.
     * Un export avec un paramètre `solution` vide retourne des décisions ayant n'importe quel type de solution.
     */
    solution?: string[];

    /**
     * Combiné avec le paramètre `date_end`, permet de restreindre les résultats à un intervalle de dates, au format ISO-8601.
     */
    date_start?: string;

    /**
     * Combiné avec le paramètre `date_start`, permet de restreindre les résultats à un intervalle de dates, au format ISO-8601.
     */
    date_end?: string;

    /**
     * Type de date à prendre en compte pour l’intervalle de dates fourni pour l’export (vaut `creation` ou `update`).
     */
    date_type?: string;

    /**
     * Permet de choisir l’ordre du tri des décisions exportées ('asc' pour un tri par date chronologique ou 'desc' pour un tri par date antichronologique, vaut 'asc' par défaut).
     */
    order?: string;

    /**
     * Permet de déterminer le nombre de résultats retournés par lot (100 maximum, vaut 50 par défaut).
     */
    batch_size?: number;

    /**
     * Permet de déterminer le numéro du lot de résultats à retourner (le premier lot ayant la valeur 0).
     */
    batch?: number;

    /**
     * Lorsque ce paramètre vaut `true`, le résultat de la requête contiendra, pour chaque information retournée par défaut sous forme de clé, l'intitulé complet de celle-ci (vaut `false` par défaut).
     */
    resolve_references?: boolean;
}

/**
 * Objet retourné suite à la requête GET vers `/export`
 */
export interface ExportBatch {
    /**
     * Objet contenant les paramètres de la requête originelle.
     */
    query: ExportQuery;

    /**
     * Nombre total de décisions retournées par la requête.
     */
    total: number;

    /**
     * Temps d'exécution de la requête (en millisecondes).
     */
    took: number;

    /**
     * Liste des résultats retournés, chaque résultat étant un objet de type `searchResult`.
     * La propriété est absente si la recherche n'a retourné aucun résultat.
     */
    results?: DecisionFull[];

    /**
     * URL du lot suivant (propriété absente si le lot courant est le dernier).
     */
    next_batch?: string;

    /**
     * Indice du lot courant (le premier lot valant 0).
     */
    batch: number;

    /**
     * Nombre de résultats retournés par lot.
     */
    batch_size: number;

    /**
     * URL du lot précédent (propriété absente si le lot courant est le premier).
     */
    previous_batch?: string;
}

// Below provided values from decision schemas and corresponding conversion functions.
// Schema values synchronisation with schemas in firestore is not guaranteed and should
// be checked indicated in the decision import status.

type JuridictionName = 'COUR DE CASSATION' | "COUR D'APPEL" | 'TRIBUNAL JUDICIAIRE';

export const jurisdictionNameMapping: Record<Jurisdiction, JuridictionName> = {
    cc: 'COUR DE CASSATION',
    ca: "COUR D'APPEL",
    tj: 'TRIBUNAL JUDICIAIRE',
};

type JuridictionShort = 'Cass.' | 'CA' | 'TJ';

export const jurisdictionShortMapping: Record<Jurisdiction, JuridictionShort> = {
    cc: 'Cass.',
    ca: 'CA',
    tj: 'TJ',
};

/**
 * Possible juridictionRoom values form decisions schemas for Cour de cassation.
 * For Cour d'appel, the values are not fixed and can be found in the decisions.
 * TODO: add chamber detection regex for Cour d'appel
 */
type JuridictionRoomCC =
    | 'ASSEMBLEE PLENIERE'
    | 'CHAMBRE MIXTE'
    | '1re CHAMBRE CIVILE'
    | '2e CHAMBRE CIVILE'
    | '3e CHAMBRE CIVILE'
    | 'CHAMBRE COMMERCIALE'
    | 'CHAMBRE SOCIALE'
    | 'CHAMBRE CRIMINELLE'
    | 'CHAMBRES REUNIES'
    | 'CHAMBRE CIVILE'
    | 'CHAMBRE DES REQUETES';

/**
 * According to LawLex team, 'ordo' corresponds to type of decisions that should not appear in imported data
 * 'other', decisions with these values are almost non-existent in the data.
 */
type ChamberCCRefines = Exclude<ChamberCC, 'other' | 'ordo'>;

export const juridictionRoomCCMapping: Record<ChamberCCRefines, JuridictionRoomCC> = {
    pl: 'ASSEMBLEE PLENIERE',
    mi: 'CHAMBRE MIXTE',
    civ1: '1re CHAMBRE CIVILE',
    civ2: '2e CHAMBRE CIVILE',
    civ3: '3e CHAMBRE CIVILE',
    comm: 'CHAMBRE COMMERCIALE',
    soc: 'CHAMBRE SOCIALE',
    cr: 'CHAMBRE CRIMINELLE',
    creun: 'CHAMBRES REUNIES',
    allciv: 'CHAMBRE CIVILE',
};

/** Values from schemas for decisions CC */
type PublicationCC = 'Publié' | 'Inédit';

/**
 * Only b "Publié au Bulletin " is considered as published.
 * CA decisions do not have publication status.
 */
export const publicationCCMapping: Record<Publication, PublicationCC> = {
    b: 'Publié',
    r: 'Inédit',
    l: 'Inédit',
    c: 'Inédit',
    n: 'Inédit',
};

/** Values from decision schemas for CA */
type DecisionTypeCA = 'Arrêt' | 'Ordonnance' | 'Autre';

/** Values from decision schemas for CC */
type DecisionTypeCC = 'Arrêt' | 'Avis' | 'QPC' | 'Ordonnance' | 'Autre';

export const decisionTypeCCMapping: Record<TypeCC, DecisionTypeCC> = {
    arret: 'Arrêt',
    avis: 'Avis',
    qpc: 'QPC', // added
    ordonnance: 'Ordonnance', // added
    other: 'Autre', // added
};

export const decisionTypeCAMapping: Record<TypeCA, DecisionTypeCA> = {
    arret: 'Arrêt',
    ordonnance: 'Ordonnance',
    other: 'Autre', // added
};

export type ResultCA = 'Confirmation' | 'Infirmation' | 'Infirmation partielle' | 'Autre';

type ResultCC =
    | 'Cassation'
    | 'Rejet'
    | 'Annulation'
    | 'Avis'
    | 'Déchéance'
    | 'Désignation de juridiction'
    | 'Irrecevabilité'
    | 'Non-lieu à statuer'
    | 'QPC renvoi'
    | 'QPC autres'
    | 'Rabat'
    | 'Renvoi'
    | 'Autre';

/**
 * New values are added to the CC decision schemas to completely align with
 * Judilibre API.
 */
export const resultCCMapping: Record<Solution, ResultCC> = {
    cassation: 'Cassation',
    rejet: 'Rejet',
    annulation: 'Annulation',
    avis: 'Avis',
    decheance: 'Déchéance',
    designation: 'Désignation de juridiction',
    irrecevabilite: 'Irrecevabilité',
    nonlieu: 'Non-lieu à statuer',
    qpc: 'QPC renvoi',
    qpcother: 'QPC autres',
    rabat: 'Rabat',
    renvoi: 'Renvoi',
    other: 'Autre',
};

/**
 * @param solutionAlt - corresponds to solution_alt in DecisionShort
 * @description
 * In judilibre API, all CA decisions have `other` for `solution` key. Sometimes
 * CA decisions have a non-standardized solution_alt key that can be used to
 * to infer the result of the decision.
 * @returns ResultCA value
 */
export const resultCAMapping = (solutionAlt?: string): ResultCA => {
    if (solutionAlt) {
        if (/partielle/i.test(solutionAlt)) {
            return 'Infirmation partielle';
        }
        if (/confirm/i.test(solutionAlt)) {
            return 'Confirmation';
        }
        if (/infirm/i.test(solutionAlt)) {
            return 'Infirmation';
        }
    }

    return 'Autre';
};

type Cities =
    | 'AGEN'
    | 'AIX-EN-PROVENCE'
    | 'AMIENS'
    | 'ANGERS'
    | 'BASSE-TERRE'
    | 'BASTIA'
    | 'BESANÇON'
    | 'BORDEAUX'
    | 'BOURGES'
    | 'CAEN'
    | 'CAYENNE'
    | 'CHAMBÉRY'
    | 'COLMAR'
    | 'DIJON'
    | 'DOUAI'
    | 'FORT-DE-FRANCE'
    | 'GRENOBLE'
    | 'LIMOGES'
    | 'LYON'
    | 'METZ'
    | 'MONTPELLIER'
    | 'NANCY'
    | 'NOUMÉA'
    | 'NÎMES'
    | 'ORLÉANS'
    | 'PAPEETE'
    | 'PARIS'
    | 'PAU'
    | 'POITIERS'
    | 'REIMS'
    | 'RENNES'
    | 'RIOM'
    | 'ROUEN'
    | 'SAINT-DENIS DE LA RÉUNION'
    | 'SAINT-PIERRE'
    | 'TOULOUSE'
    | 'VERSAILLES';

export const citiesMapping: Record<Location, Cities> = {
    ca_agen: 'AGEN',
    ca_aix_provence: 'AIX-EN-PROVENCE',
    ca_amiens: 'AMIENS',
    ca_angers: 'ANGERS',
    ca_basse_terre: 'BASSE-TERRE',
    ca_bastia: 'BASTIA',
    ca_besancon: 'BESANÇON',
    ca_bordeaux: 'BORDEAUX',
    ca_bourges: 'BOURGES',
    ca_caen: 'CAEN',
    ca_cayenne: 'CAYENNE',
    ca_chambery: 'CHAMBÉRY',
    ca_colmar: 'COLMAR',
    ca_dijon: 'DIJON',
    ca_douai: 'DOUAI',
    ca_fort_de_france: 'FORT-DE-FRANCE',
    ca_grenoble: 'GRENOBLE',
    ca_limoges: 'LIMOGES',
    ca_lyon: 'LYON',
    ca_metz: 'METZ',
    ca_montpellier: 'MONTPELLIER',
    ca_nancy: 'NANCY',
    ca_nimes: 'NÎMES',
    ca_noumea: 'NOUMÉA',
    ca_orleans: 'ORLÉANS',
    ca_papeete: 'PAPEETE',
    ca_paris: 'PARIS',
    ca_pau: 'PAU',
    ca_poitiers: 'POITIERS',
    ca_reims: 'REIMS',
    ca_rennes: 'RENNES',
    ca_riom: 'RIOM',
    ca_rouen: 'ROUEN',
    ca_st_denis_reunion: 'SAINT-DENIS DE LA RÉUNION',
    ca_toulouse: 'TOULOUSE',
    ca_versailles: 'VERSAILLES',
};
