import { Tree, CollectionName } from '@livv/models';
import admin from 'firebase-admin';

export const getKeywordTrees = async (db: admin.firestore.Firestore) => {
    const keywordTrees: Record<string, Tree> = {};
    const keywordTreeDocs = await db
        .collection(CollectionName.TREES)
        .where('isKeyword', '==', true)
        .get();
    keywordTreeDocs.forEach((doc) => {
        keywordTrees[doc.id] = doc.data() as Tree;
    });

    return keywordTrees;
};
