export type SchemaField<T = string> = {
    comment?: string;
    default?: boolean;
    format?: string;
    is?: T;
    labels?: string[];
    none?: boolean;
    oneOf?: T[];
    someOf?: T[];
};

export type SchemaFieldKey = keyof Omit<SchemaField, 'comment'>;

export const SchemaFielKeyTranslation: Record<keyof SchemaField, string> = {
    // TODO rather use i18n after refacto
    comment: 'Commentaire',
    default: 'Texte libre',
    format: 'Format défini',
    is: 'Valeur imposée',
    labels: 'Labels',
    none: 'Vide',
    oneOf: 'Choix multiple (1 valeur)',
    someOf: 'Choix multiple (plusieurs valeurs)',
};
