import { CollectionName } from './collections';
import {
    Analysis,
    Article,
    CodeArticle,
    Decision,
    GlossaryTerm,
    LawText,
    PublicationSheet,
    Statistic,
} from './firebaseRecords';

/**
 * Collections with relations:
 * Excerpts of publication (also called ouvrages, doctrine) => cite decisions and laws,
 * Decisions => cite laws and decisions,
 * Laws => cite laws,
 * Statistics => cite decisions,
 * Analyses => cite laws.
 * Note: CODE_ARTICLE and LAW_TEXT are both laws.
 */
export enum CitationCollection {
    ANALYSES = CollectionName.ANALYSES,
    ARTICLES = CollectionName.ARTICLES,
    CODE_ARTICLES = CollectionName.CODE_ARTICLES,
    DECISIONS = CollectionName.DECISIONS,
    LAW_TEXTS = CollectionName.LAW_TEXTS,
    PUBLICATION_SHEETS = CollectionName.PUBLICATION_SHEETS,
    STATISTICS = CollectionName.STATISTICS,
    GLOSSARY_TERMS = CollectionName.GLOSSARY_TERMS,
}

export type CitationDocument =
    | Analysis
    | Article
    | CodeArticle
    | Decision
    | GlossaryTerm
    | LawText
    | PublicationSheet
    | Statistic;
