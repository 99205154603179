type Constructor<T> = {
    new (...args: unknown[]): T;
};

// TODO: see if target is still provided in function calls
export const catchError = (ErrorClass: Constructor<Error>) =>
    function logMethod(
        target: Record<string, unknown>,
        propertyName: string,
        propertyDesciptor: PropertyDescriptor,
    ): PropertyDescriptor {
        const method = propertyDesciptor.value;

        const wrappedMethod = (...args: unknown[]) => {
            const stringArgs = args.map((a) => JSON.stringify(a)).join();
            try {
                const result = method.apply(wrappedMethod, args);

                return result;
            } catch (e) {
                if (e instanceof ErrorClass) {
                    console.error(e);
                    throw new Error(
                        `Error ${e} was thrown by a call to ${propertyName} with arguments ${stringArgs}`,
                    );
                } else {
                    throw e;
                }
            }
        };

        return { ...propertyDesciptor, value: wrappedMethod };
    };
