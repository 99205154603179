import { Timestamp } from '@firebase/firestore-types';
import { LawScope } from '../lawScope';
import { Keyword } from './keyword';

export enum Coordinates2D {
    x = 'x',
    y = 'y',
}

export interface Point2D {
    [Coordinates2D.x]: number;
    [Coordinates2D.y]: number;
}

export type StatisticValue = null | boolean | number | Point2D;

type DateType = Timestamp | Date;

export interface DecisionData<T extends StatisticValue, D extends DateType = Timestamp> {
    decisionId: string;
    performedAt: D;
    title: string;
    value: T;
}

export type StatisticRow<T extends StatisticValue, D extends DateType = Timestamp> =
    | {
          isNode: true;
          label: string;
          position: number;
          rows: Record<string, StatisticRow<T, D>>;
      }
    | {
          isNode: false;
          label: string;
          position: number;
          values: DecisionData<T, D>[];
      };

export type StatisticRows<T extends StatisticValue = null, D extends DateType = Timestamp> = Record<
    string,
    StatisticRow<T, D>
>;

export type StatisticFormRow<T extends StatisticValue = null> =
    | {
          name: string;
          isNode: true;
          label?: string;
          rows?: StatisticFormRow<T>[];
      }
    | {
          name: string;
          isNode: false;
          label?: string;
          values?: DecisionData<T, Date>[];
      };

export interface ColumnTitle {
    label?: string;
    columnTitles?: ColumnTitle[];
    width?: number; // Number ow columns it should overlap, default is 1
}

export enum StatisticType {
    BOOLEAN = 'boolean',
    COUNT = 'count',
    PIE = 'pie',
    SCATTER_PLOT = 'scatterPlot',
    VALUE = 'value',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStatisticTypeLabel = (type: StatisticType) => {
    switch (type) {
        case StatisticType.BOOLEAN:
            return 'Oui / Non';
        case StatisticType.COUNT:
            return 'Dénombrement';
        case StatisticType.PIE:
            return 'Camembert';
        case StatisticType.SCATTER_PLOT:
            return 'Nuage de points';
        case StatisticType.VALUE:
            return 'Valeur';
        default:
            return 'Inconnu';
    }
};

interface StatisticBase<T extends StatisticValue, D extends DateType> {
    category: string;
    columnTitles: ColumnTitle[];
    createdAt: D;
    description?: string;
    displayTableView: boolean;
    isPublished: boolean;
    isStale: boolean;
    keywords: Keyword[];
    lawScopes: LawScope[];
    rows: StatisticRows<T, D>;
    title: string;
    type: StatisticType;
    updatedAt: D;
}

export type Statistic<T extends StatisticValue = null> = StatisticBase<T, Timestamp>;

export interface StatisticWithId extends Statistic<StatisticValue> {
    // TODO: VOG-2012 Replace `statisticId` by `id`
    statisticId: string;
}

export type StatisticRaw<T extends StatisticValue = null> = StatisticBase<T, Date>;

export interface StatisticForm<T extends StatisticValue = null>
    extends Omit<StatisticRaw<T>, 'rows'> {
    rows: StatisticFormRow<T>[];
}

export interface StatisticEntry<T extends StatisticValue> {
    decisionId: string;
    path: string[];
    performedAt: Timestamp;
    statisticId: string;
    title: string;
    value: T;
}

export interface StatisticRawEntry<T extends StatisticValue> {
    entryId: string;
    path: string;
    value: T;
}
