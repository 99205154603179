import { Timestamp } from '@firebase/firestore-types';
import { LawScope } from '../lawScope';

export enum LawType {
    JBA = 'JBA',
    JBL = 'JBL',
    LEGIARTI = 'LEGIARTI',
}

export interface LawTextCore {
    article: string;
    context: string[];
    fullText?: string;
    issuer: string;
    language: string;
    lawScopes: LawScope[];
    nature: string;
    number: string;
    status: string;
    summary: string;
    title: string;
    type: string;
}

export interface LawText extends LawTextCore {
    performedAt: Timestamp | false;
    createdAt: Timestamp;
    updatedAt: Timestamp;
}

export interface LawTextRaw extends LawTextCore {
    performedAt: Date | false;
    createdAt: Date;
    updatedAt: Date;
}
