import { QueryDocumentSnapshot, QuerySnapshot } from '@firebase/firestore-types';
import { CollectionName, Decision, DecisionRaw } from '@livv/models';
import { Firestore as AdminFirestore } from 'firebase-admin/firestore';
import { formatDate } from '../date';

const FIRESTORE_MAX_DISJUNCTIONS = 30;
const UNIQUE_ID_MAX_SIZE = 1000;

/** Remove all non-alphanumerical characters */
const normalizeString = (number: string) => number.toUpperCase().replace(/[^A-Z0-9]/g, '');

/** DATA MIGRATION MUST BE DONE IF THIS FUNCTION IS MODIFIED */
export const getUniqueIds = ({
    number,
    city,
    performedAt,
    juridictionName,
}: Pick<DecisionRaw, 'number' | 'city' | 'performedAt' | 'juridictionName'>): string[] => {
    const uniqueIds = [];
    const date = formatDate(performedAt, 'custom', 'yyyy-MM-dd');
    const cityNormalized = normalizeString(city);
    const juridictionNameNormalized = normalizeString(juridictionName);
    const compositeId = `${juridictionNameNormalized}_${cityNormalized}_${date}`;
    for (const currentNumber of number) {
        if (!number) {
            continue;
        }
        const numberNormalized = normalizeString(currentNumber);
        let compositeIdWithNumber = `${compositeId}_${numberNormalized}`;

        // Some decisions have number as a free text and may contains large strings pasted by mistake
        if (compositeIdWithNumber.length > UNIQUE_ID_MAX_SIZE) {
            compositeIdWithNumber = compositeIdWithNumber.slice(0, UNIQUE_ID_MAX_SIZE);
            console.error(
                `Decision number in a decision is too long, it has been truncated to ${UNIQUE_ID_MAX_SIZE} characters: ${compositeIdWithNumber}`,
            );
        }

        uniqueIds.push(compositeIdWithNumber);
    }

    return uniqueIds.length > 0 ? uniqueIds : [compositeId];
};

/** Returns an empty array if decision is unique or an array of duplicates if found */
export const findDuplicates =
    (db: AdminFirestore) =>
    async (uniqueIds: string[]): Promise<QueryDocumentSnapshot<Decision>[]> => {
        if (uniqueIds.length > FIRESTORE_MAX_DISJUNCTIONS) {
            console.warn(`Decision has too many composite ids. Slicing to 30 first ids.`);
        }

        return (
            (await db
                .collection(CollectionName.DECISIONS)
                .where(
                    `uniqueIds`,
                    'array-contains-any',
                    uniqueIds.slice(0, FIRESTORE_MAX_DISJUNCTIONS),
                )
                .get()) as unknown as QuerySnapshot<Decision>
        ).docs;
    };
