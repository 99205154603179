import { Languages } from '../languages';
import { ModelType } from '../modelType';
import { Status } from './status';
import { WithAuthorization } from './withAuthorization';

export type PrintableDocumentType = ModelType;

export type DocumentToGenerate = {
    displaySameCaseDecisions?: boolean;
    id: string;
    linkedAnalyses?: string[];
    note?: string;
    type: PrintableDocumentType;
    versionId?: string;
    withAssociatedDocuments?: boolean;
};

export interface GenerateDocumentPdf extends WithAuthorization {
    input: {
        language: Languages;
        documents: DocumentToGenerate[];
    };
    output: {
        status: Status;
        url: string;
    };
}

export type GenerateDocumentPdfFunc = (
    input: Omit<GenerateDocumentPdf['input'], 'documentType'>,
    uid?: string,
) => Promise<GenerateDocumentPdf['output']>;

export const isGenerateDocumentPdf = (
    args?: Partial<GenerateDocumentPdf['input']>,
): args is GenerateDocumentPdf['input'] =>
    args !== undefined && args.language !== undefined && Array.isArray(args.documents);
