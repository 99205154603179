import { Timestamp } from '@firebase/firestore-types';
import { ModelType } from '../modelType';

export enum UserStatAction {
    NEW = 'new',
    EDIT = 'edit',
}

export interface UserStatCore {
    action: UserStatAction;
    type: ModelType;
    id: string;
}

export interface UserStat extends UserStatCore {
    performedAt: Timestamp;
}

export interface UserStatRaw extends UserStatCore {
    performedAt: Date;
}
