import { CollectionName } from '@livv/models';
import { firestore } from 'firebase-admin';
import { DocumentData, DocumentReference } from 'firebase-admin/firestore';

const getData =
    (db: firestore.Firestore) =>
    async <T extends DocumentData>(
        docId: string,
        collectionName: CollectionName,
    ): Promise<T | undefined> => {
        const doc = db.collection(collectionName).doc(docId) as DocumentReference<T>;
        const snapshot = await doc.get();

        return snapshot.data();
    };

export default getData;
