import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from './authentification';
import { auth } from './firebase';
import { userPrivileges, users } from 'models/users';

async function fetchUserWithPermissions(user: User | null) {
    if (user) {
        user.record = (await getDoc(doc(users, user.uid))).data();
        user.privileges = (await getDoc(doc(userPrivileges, user.uid))).data();
    }

    return user;
}

export default function useAuthentification() {
    const [user, setUser] = useState<User | null>(null);
    const history = useHistory();
    useEffect(
        () =>
            auth.onAuthStateChanged(async (currentUser: User | null) => {
                if (currentUser) {
                    currentUser = await fetchUserWithPermissions(currentUser);
                    setUser(currentUser);
                } else {
                    history.push(
                        `/login?redirect_url=${encodeURIComponent(
                            history.location.pathname + history.location.search,
                        )}`,
                    );
                }
            }),
        [history],
    );

    return user;
}
