/* eslint-disable @typescript-eslint/no-use-before-define */
type ClassType = ClassList | ClassObject | string | boolean | null | undefined;
type ClassObject = Record<string, unknown>;
type ClassList = ClassType[];

/**
 * Function to return whether value is falsy or an empty object/array
 * @param value The value to test
 * @returns the test result
 */
const isValueTruthy = (value: unknown): boolean => {
    if (!value) return false;
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'object') return Object.keys(value).length > 0;

    return Boolean(value);
};

/**
 * Function used to reduce classes into a single string
 * @param classes The list of classes to filter/merge
 * @returns A string concatenating given classes
 */
const reduceArray = (classes: ClassType[]): string =>
    classes
        .reduce<string>((list: string, currentClass: ClassType) => {
            const cleanClasses = cleanClass(currentClass);

            return cleanClasses ? `${list} ${cleanClasses}` : list;
        }, '')
        .trim();

/**
 * Utilisty function to remove empty classes and flatten every other types
 * @param input A single ClassType item
 * @returns The class list found from the item
 */
const cleanClass = (input: ClassType): string => {
    if (!input || !isValueTruthy(input)) return '';
    if (Array.isArray(input)) return reduceArray(input);
    if (typeof input === 'object') {
        return cleanClass(
            Object.entries(input).reduce(
                (list, [key, value]) => (isValueTruthy(value) ? [...list, key] : list),
                [] as string[],
            ),
        );
    }

    return input as string;
};

const mergeClasses = (...classes: ClassType[]): string => reduceArray(classes);

export { mergeClasses };
