import { Decision } from '../firebaseRecords';
import { SchemaField } from './schemaField';

export type Guideline = {
    [key in keyof Pick<
        Decision,
        | 'city'
        | 'decisionType'
        | 'defendant'
        | 'juridictionRoom'
        | 'jury'
        | 'language'
        | 'number'
        | 'plaintiff'
        | 'publicationStatus'
        | 'summary'
    >]: SchemaField;
} & {
    juridictionShort: SchemaField;
    juridictionType: SchemaField;
    result: SchemaField;
};

export type DecisionSchema = Record<string, Guideline>;

export const guidelineKeyTranslation: Record<keyof Guideline, string> = {
    // TODO rather use i18n after refacto
    city: 'Ville',
    decisionType: 'Type de décision',
    defendant: 'Défendeur',
    juridictionRoom: 'Chambre',
    juridictionShort: 'Appellation abrégée',
    juridictionType: 'Type de juridiction',
    jury: 'Composition de la juridiction',
    language: 'Langue',
    number: 'Numéro',
    plaintiff: 'Demandeur',
    publicationStatus: 'Statut',
    result: 'Dispositif',
    summary: 'Intitulé',
};
