import { Timestamp } from '@firebase/firestore-types';

export type UserRole = 'admin' | 'user';

export interface UserPrivilegesDefaultValue {
    isPaidCustomer: boolean;
    role: UserRole;
}

export const userPrivilegesDefaultValue: UserPrivilegesDefaultValue = {
    isPaidCustomer: false,
    role: 'user',
};

export interface UserPrivileges extends UserPrivilegesDefaultValue {
    createdAt: Timestamp;
    email: string;
    featureFlags?: string[];
    skipEmailVerification?: boolean;
    skipSessionVerification?: boolean;
    subscriptionEndDate?: number;
    subscriptionStartDate?: number;
    trialEndDate?: number;
}
