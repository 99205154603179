import { NumberDetails } from '@livv/models/LawLex';

export const transforms = {
    fromRaw: (lawlex: string, { prefix, suffix }: NumberDetails) => {
        const lawLexRegex = new RegExp(`^${prefix}([0-9]{4})0*([0-9]+)${suffix}$`);
        const match = lawlex.match(lawLexRegex);
        if (!match) {
            throw new Error('Problem computing next lawlex number');
        }
        const [, year, number] = match;

        return {
            number,
            prefix,
            suffix,
            year,
        };
    },
    toRaw: ({ prefix, suffix, year, number }: NumberDetails) =>
        `${prefix}${year}${`00000000${number}`.slice(-8)}${suffix}`,
};

export const nextLawLex = ({ prefix, suffix, year, number }: NumberDetails): NumberDetails => {
    const currentYear = new Date().getFullYear();
    if (currentYear !== year) {
        number = 0;
    }

    return {
        number: number + 1,
        prefix,
        suffix,
        year: currentYear,
    };
};
