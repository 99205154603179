import { Timestamp } from '@firebase/firestore-types';
import { LawScope } from '../lawScope';
import { Keyword } from './keyword';

export enum MainKeywordEnum {
    BANKRUPTCY_LAW = 'BANKRUPTCY_LAW',
    BUSINESS_CONTRACTS = 'BUSINESS_CONTRACTS',
    COMMERCIAL_LEASES = 'COMMERCIAL_LEASES',
    COMPETITION = 'COMPETITION',
    COMPLIANCE = 'COMPLIANCE',
    CONSUMPTION = 'CONSUMPTION',
    CORPORATE_CRIMINAL_LIABILITY = 'CORPORATE_CRIMINAL_LIABILITY',
    CORPORATE_LAW = 'CORPORATE_LAW',
    DISTRIBUTION = 'DISTRIBUTION',
    EU_BUSINESS_LAW = 'EU_BUSINESS_LAW',
    INDUSTRIAL_PROPERTY_LAW = 'INDUSTRIAL_PROPERTY_LAW',
}

const mainKeywordNameValue = [
    {
        label: 'Droit de la concurrence',
        value: MainKeywordEnum.COMPETITION,
    },
    {
        label: 'Droit de la consommation',
        value: MainKeywordEnum.CONSUMPTION,
    },
    {
        label: 'Droit de la distribution',
        value: MainKeywordEnum.DISTRIBUTION,
    },
    {
        label: 'Droit européen des affaires',
        value: MainKeywordEnum.EU_BUSINESS_LAW,
    },
    {
        label: 'Procédures collectives',
        value: MainKeywordEnum.BANKRUPTCY_LAW,
    },
    {
        label: 'Droit des sociétés',
        value: MainKeywordEnum.CORPORATE_LAW,
    },
    {
        label: 'Baux commerciaux',
        value: MainKeywordEnum.COMMERCIAL_LEASES,
    },
    {
        label: "Contrats d'affaires",
        value: MainKeywordEnum.BUSINESS_CONTRACTS,
    },
    {
        label: 'Propriété industrielle',
        value: MainKeywordEnum.INDUSTRIAL_PROPERTY_LAW,
    },
    {
        label: 'Droit pénal des affaires',
        value: MainKeywordEnum.CORPORATE_CRIMINAL_LIABILITY,
    },
    {
        label: 'Droit de la compliance',
        value: MainKeywordEnum.COMPLIANCE,
    },
];

const mainKeywordNameValueMap = new Map<MainKeywordEnum | null, string>();
mainKeywordNameValue.forEach(({ label, value }) => mainKeywordNameValueMap.set(value, label));

export { mainKeywordNameValue, mainKeywordNameValueMap };

export interface GlossaryTermContent {
    isRedirection: boolean;
    redirectionHref?: string;
    redirectionId?: string;
    redirectionLabel?: string;
    text: string;
}

export interface GlossaryTermCore {
    content: GlossaryTermContent;
    description?: string;
    firstLetter: string;
    keywords: Keyword[];
    language: string;
    lawScopes: LawScope[];
    linkedBiblio: string[];
    linkedDecisions: string[];
    linkedStatistics: string[];
    mainKeywords: MainKeywordEnum[];
    title: string;
}

export interface GlossaryTerm extends GlossaryTermCore {
    createdAt: Timestamp;
    updatedAt: Timestamp;
}

export interface GlossaryTermRaw extends GlossaryTermCore {
    createdAt: Date;
    updatedAt: Date;
}
