import { DocumentData } from '@firebase/firestore-types';
import {
    Article,
    Decision,
    GlossaryTerm,
    LawText,
    SplittedCases,
    Statistic,
} from '@livv/models/firebaseRecords';
import { decisionIdRegex } from '@livv/models/LawLex';
import { ModelType } from '@livv/models/modelType';

// Analysis is fetch from decision, do it from related code in apps
export const getDocumentTitle = (documentData: DocumentData, modelType: ModelType): string => {
    switch (modelType) {
        case ModelType.ARTICLE:
        case ModelType.DECISION:
        case ModelType.GLOSSARY_TERM:
        case ModelType.LAW_TEXT:
        case ModelType.STATISTIC:
        default:
            // Force to return a string and not an empty string, because it's not possible to have an empty title
            return (documentData as Article | Decision | GlossaryTerm | LawText | Statistic).title;
    }
};

export const getModelTypeFromLawLexEnds = (prefix: string, suffix: string): ModelType | null => {
    const lawLexFormat = `${prefix}_${suffix}`;
    switch (true) {
        case lawLexFormat === 'Art_':
            return ModelType.ARTICLE;
        case lawLexFormat === 'Glo_':
            return ModelType.GLOSSARY_TERM;
        case lawLexFormat === 'LawLex_JBA':
        case lawLexFormat === 'LawLex_JBL':
        case lawLexFormat === 'LEGIARTI_':
            return ModelType.LAW_TEXT;
        case lawLexFormat === 'LawLex_JBN':
            return ModelType.ANALYSIS;
        case lawLexFormat === 'LawLex_JBJ':
            return ModelType.DECISION;
        case lawLexFormat === 'Stat_':
            return ModelType.STATISTIC;
        default:
            return null;
    }
};

export const getModelTypeFromLawLex = (text: string): ModelType | null => {
    if (!text) return null;
    const lawLexRegex = /(Art|Glo|LawLex|LEGIARTI|Stat)[0-9]{12}(JBA|JBJ|JBN|JBL|)/g;
    const lawLexMatches = [...text.matchAll(lawLexRegex)];
    if (0 === lawLexMatches.length) {
        return ModelType.PUBLICATION_SHEET; // TODO - VOG-950: format publicationSheets ids;
    }
    const [prefix, suffix] = lawLexMatches[0].slice(1, 3);

    return getModelTypeFromLawLexEnds(prefix, suffix);
};

/**
 * Splits a list of string in two, depending whether it matches an ID regex
 * @param initial The list of cases as filled by our users
 * @returns Two lists, the first one with raw titles filled as is, the second with decision IDs
 */
export const splitCasesTitleID = (initial: string[]): SplittedCases =>
    initial.reduce(
        ([titles, ids], sameCase) =>
            decisionIdRegex.test(sameCase)
                ? [titles, [...ids, sameCase]]
                : [[...titles, sameCase], ids],
        [[], []] as SplittedCases,
    );
