import { User as firebaseUser } from 'firebase/auth';
import { DocumentData } from 'firebase/firestore';
import React, { FC, ReactElement, ReactNode, useContext } from 'react';
import { auth } from './firebase';
import useAuthentification from './useAuthentification';

export interface User extends firebaseUser {
    record?: DocumentData;
    privileges?: DocumentData;
}

const canAccessApp = ({ privileges }: User) => privileges && privileges.role === 'admin';

export const AuthContext = React.createContext<User | null>(null);

interface AuthProviderProps {
    unauthorizedPageElement: ReactElement;
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children, unauthorizedPageElement }) => {
    const user = useAuthentification();
    if (!user) {
        return null;
    }

    return canAccessApp(user) ? (
        <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
    ) : (
        unauthorizedPageElement
    );
};

export const useUser = () => {
    const user = useContext(AuthContext);
    if (!user) {
        throw new Error('Trying to access a user but not here');
    }

    return user;
};

export const signout = () =>
    // force page refresh to remove redirectUrl
    auth.signOut().finally(() => {
        window.location.href = '/login';
    });
