/* eslint-disable no-underscore-dangle */
import { TreeNode } from './firebaseRecords/treeNodes';
// eslint-disable-next-line import/no-cycle
import { TreeContent, TreeWithId } from './firebaseRecords/trees';
import { LanguageUppercase } from './languages';
import PathResolutionError from './pathResolutionError';
import { Path } from './paths';

export const displayedTreesCategories = [
    'Autre',
    'Baux commerciaux',
    'Concurrence',
    'Consommation',
    'Distribution',
    'Droit européen des affaires',
];

export interface TreeDataNode {
    children?: TreeDataNode[];
    hasSheet?: boolean;
    id: TreeNode['id'];
    isPublished?: boolean;
    prefix?: string;
    title: string;
}

export interface PathTitle {
    subpath: string[];
    title: string;
    typo: string;
}

export interface CodeArticleRef {
    cid: string;
    id: string;
}

export interface CodeTreeDataNode extends TreeDataNode {
    articleList: CodeArticleRef[];
}

// TODO: duplicated with public/utils; Centralize in @livv/utils and remove duplicates
// https://thetribeio.atlassian.net/browse/VOG-1813
function convertToRoman(numRef: number) {
    let num = numRef;
    const roman: { [key: string]: number } = {
        M: 1000, // order by decreasing value required
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
    };
    let str = '';

    for (const i of Object.keys(roman)) {
        const q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
    }

    return str;
}

const convertToRomanMinLetter = (num: number) => convertToRoman(num).toLowerCase();
const convertToMajLetter = (num: number) => String.fromCharCode(64 + num);
const convertToMinLetter = (num: number) => String.fromCharCode(96 + num);
const convertToInteger = (num: number) => num.toString();

const typoFormatters: [(i: string) => string, (num: number) => string][] = [
    [(i: string) => `Livre ${i}`, convertToInteger],
    [(i: string) => `Partie ${i}`, convertToInteger],
    [(i: string) => `Titre ${i}`, convertToInteger],
    [(i: string) => `Chapitre ${i}`, convertToInteger],
    [(i: string) => `Section ${i}`, convertToInteger],
    [(i: string) => i, convertToRoman],
    [(i: string) => i, convertToMajLetter],
    [(i: string) => `${i}°`, convertToInteger],
    [(i: string) => `${i})`, convertToMinLetter],
    [(i: string) => `${i})`, convertToRomanMinLetter],
];

const typoFormattersEN: [(i: string) => string, (num: number) => string][] = [
    [(i: string) => `Book ${i}`, convertToInteger],
    [(i: string) => `Part ${i}`, convertToInteger],
    [(i: string) => `Title ${i}`, convertToInteger],
    [(i: string) => `Chapter ${i}`, convertToInteger],
    [(i: string) => `Section ${i}`, convertToInteger],
    [(i: string) => i, convertToRoman],
    [(i: string) => i, convertToMajLetter],
    [(i: string) => `${i}°`, convertToInteger],
    [(i: string) => `${i})`, convertToMinLetter],
    [(i: string) => `${i})`, convertToRomanMinLetter],
];

const typoFormatter = (level: number, language: LanguageUppercase) =>
    language === 'EN'
        ? typoFormattersEN[Math.min(level, typoFormatters.length - 1)]
        : typoFormatters[Math.min(level, typoFormatters.length - 1)];

const formatRawIndexes = (indexes: number[]): (string | number)[] => {
    // inside introduction
    if (indexes[0] === 0) {
        if (indexes.length === 1) return [];

        return [-1, 'Intro', ...indexes.slice(1)];
    }

    return [indexes[0] - 1, ...indexes.slice(1)];
};

const formatIndex = (
    level: number,
    index: number | string,
    language: LanguageUppercase,
    dummyLevel = false,
): string | false => {
    if (typeof index === 'string') return index;

    if (index === -1) return false;

    return typoFormatter(dummyLevel ? level + 1 : level, language)[1](index + 1);
};

const generateTypography = (
    rawIndexes: number[],
    // eslint-disable-next-line default-param-last
    full = false,
    language: LanguageUppercase,
    dummyLevel = false,
): string => {
    const indexes = formatRawIndexes(rawIndexes);
    const level = indexes.length - 1;
    if (level < 0) return '';

    const formattedIndexes = full
        ? indexes
              .map((i, l) => formatIndex(l, i, language, dummyLevel))
              .filter((i) => i)
              .join('.')
        : formatIndex(level, indexes[indexes.length - 1], language, dummyLevel) || '';

    return typoFormatter(dummyLevel ? level + 1 : level, language)[0](formattedIndexes);
};

const insert = <T>(arr: T[], index: number, newItem: T) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
];

export class TreeData {
    content: TreeDataNode[];

    idList: string[] = [];

    theme?: string;

    title: string;

    constructor(content: TreeDataNode[] | TreeContent, title: string, theme?: string) {
        this.content = Array.isArray(content) ? content : TreeContent.toRaw(content);
        this.theme = theme;
        this.title = title;
    }

    treeContent() {
        return TreeContent.fromRaw(this.content);
    }

    getNode(path: string[], byTitle?: boolean): TreeDataNode {
        try {
            return TreeData._getNode(new Path(path), this.content, byTitle);
        } catch (e) {
            console.error(JSON.stringify(e));
            throw new Error(`Path ${path.join('.')} not found in tree ${this.title}`);
        }
    }

    getNodesIdList(): string[] {
        if (this.idList.length) return this.idList;

        this.idList = this._getNodeIdList();

        return this.idList;
    }

    getPublishedNodesPaths(): string[][] {
        return TreeData.getPublishedNodes(this.content);
    }

    getLocalNodeTypography(
        path: string[],
        language: LanguageUppercase,
        dummyLevel?: boolean,
    ): string {
        return generateTypography(
            TreeData._getPathIndexes(new Path(path), this.content),
            false,
            language,
            dummyLevel,
        );
    }

    getFullNodeTypography(path: string[], language = LanguageUppercase.FR, dummyLevel = false) {
        return generateTypography(
            TreeData._getPathIndexes(new Path(path), this.content),
            true,
            language,
            dummyLevel,
        );
    }

    getIndexes(path: string[]) {
        // TODO should use formatIndexes
        return TreeData._getPathIndexes(new Path(path), this.content);
    }

    getDepth(path: string[]) {
        return this.getIndexes(path).length;
    }

    getPathTitles(path: string[]): string[] {
        try {
            return path.map((_, index) => this.getNode(path.slice(0, index + 1)).title);
        } catch (error) {
            console.error('Failed to get path titles', { error, path });
            throw new Error(
                `Failed to get path titles for path ${path.join('.')} in tree ${this.title}`,
            );
        }
    }

    getPathTitlesWithTypos(
        path: string[],
        language: LanguageUppercase,
        dummyLevel?: boolean,
    ): PathTitle[] {
        return path.map((_, index) => {
            const subpath = path.slice(0, index + 1);
            const { title } = this.getNode(subpath);
            const typo = this.getLocalNodeTypography(subpath, language, dummyLevel);

            return { subpath, title, typo };
        });
    }

    getPathIds(pathTitles: string[]) {
        return pathTitles.map((_, index) => this.getNode(pathTitles.slice(0, index + 1), true).id);
    }

    addNode(node: TreeDataNode, path: string[], index?: number) {
        this.content = TreeData._transformChildrenOf(new Path(path), this.content, (children) =>
            typeof index === 'number' ? insert(children, index, node) : [...children, node],
        );
    }

    removeNode(pathArg: string[]) {
        const path = new Path(pathArg);
        const nodeId = path.nodeId();

        this.content = TreeData._transformChildrenOf(path.parentPath(), this.content, (children) =>
            children.filter(({ id }) => id !== nodeId),
        );
    }

    moveNode(path: string[], newParentPath: string[], newIndex: number) {
        const node = this.getNode(path);
        this.removeNode(path);
        this.addNode(node, newParentPath, newIndex);

        const parentPath = new Path(path).parentPath();
        const nextParentPath = new Path(newParentPath);

        return TreeData._collectNodeDescendants(
            node,
            ({ path: relativePath }) =>
                [
                    parentPath.descendantPath(relativePath).ids,
                    nextParentPath.descendantPath(relativePath).ids,
                ] as const,
        );
    }

    replaceNode(node: Partial<TreeDataNode>, pathArg: string[]) {
        const path = new Path(pathArg);
        this.content = TreeData._transformChildrenOf(path.parentPath(), this.content, (children) =>
            children.map((child) => (child.id === path.nodeId() ? { ...child, ...node } : child)),
        );
    }

    getDescendantsPaths(pathArg: string[]) {
        const parentPath = new Path(pathArg).parentPath();

        return TreeData._collectNodeDescendants(
            this.getNode(pathArg),
            ({ path: relativePath }) => parentPath.descendantPath(relativePath).ids,
        );
    }

    getPathFromId(nodeId: string, nodes = this.content): string[] {
        for (const currentNode of nodes) {
            if (currentNode.id === nodeId) return [nodeId];
            if (currentNode.children && currentNode.children.length) {
                const found = this.getPathFromId(nodeId, currentNode.children);

                if (found.length) return [currentNode.id, ...found];
            }
        }

        return [];
    }

    getSearchMatches(
        cleaningMethod: (init: string) => string,
        searchQuery?: string,
        nodes = this.content,
    ): string[] {
        if (!searchQuery) return [];

        return nodes.reduce((matches, node) => {
            const nodeMatches = [];
            const titleMatch =
                node.title && cleaningMethod(node.title).includes(cleaningMethod(searchQuery));

            if (titleMatch) nodeMatches.push(node.id);
            if (node?.children?.length)
                nodeMatches.push(
                    ...this.getSearchMatches(cleaningMethod, searchQuery, node.children),
                );

            return [...matches, ...nodeMatches];
        }, [] as string[]);
    }

    _getNodeIdList(nodes = this.content): string[] {
        return nodes.reduce((list, node) => {
            const childrenIds = node?.children?.length ? this._getNodeIdList(node.children) : [];

            return [...list, node.id, ...childrenIds];
        }, [] as string[]);
    }

    static _getNode(path: Path, nodes: TreeDataNode[], byTitle?: boolean): TreeDataNode {
        const [nodeId, nextPath] = path.popRoot();
        const node = nodes.find(({ id, title }) => (byTitle ? title === nodeId : id === nodeId));

        if (!node) {
            throw new Error(`Node ${nodeId} not found`);
        }
        if (nextPath.empty()) {
            return node;
        }
        if (!node.children)
            throw new Error(
                `Remaining path ${JSON.stringify(path)} is not found. Node ${JSON.stringify(
                    node,
                )} does not have children.`,
            );

        return this._getNode(nextPath, node.children, byTitle);
    }

    static _transformChildrenOf(
        path: Path,
        // eslint-disable-next-line default-param-last
        nodes: TreeDataNode[] | undefined = [],
        callback: (nodes: TreeDataNode[]) => TreeDataNode[],
    ): TreeDataNode[] {
        if (path.empty()) return callback(nodes);

        const [nodeId, nextPath] = path.popRoot();

        const node = nodes.find(({ id }) => id === nodeId);
        if (!node)
            throw new PathResolutionError(
                `Inconsistency: no node for remaining path ${path.toString()}`,
            );

        return nodes.map((root) =>
            root === node
                ? {
                      ...root,
                      children: this._transformChildrenOf(nextPath, root.children, callback),
                  }
                : root,
        );
    }

    static _collectNodeDescendants<T>(
        node: TreeDataNode,
        callback: (args: { node: TreeDataNode; path: Path }) => T,
        parentPath: Path = new Path([]),
    ): T[] {
        const path = parentPath.childPath(node.id);

        return [callback({ node, path })].concat(
            ...(node.children || []).map((child) =>
                this._collectNodeDescendants(child, callback, path),
            ),
        );
    }

    static _getPathIndexes(
        path: Path,
        nodes: TreeDataNode[],
        parentIndexes: number[] = [],
    ): number[] {
        const [nodeId, nextPath] = path.popRoot();
        const index = nodes.findIndex(({ id }) => id === nodeId);

        if (index === -1)
            throw new Error(
                `Can't find node with id ${nodeId}, at level ${
                    parentIndexes.length
                }: nodes have ids ${nodes.map(({ id }) => id)}`,
            );
        const indexes = [...parentIndexes, index];

        if (nextPath.empty()) {
            return indexes;
        }
        const node = nodes[index];
        if (!node.children)
            throw new Error(`Node ${nodeId} doesn't have children, cannot get indexes`);

        return this._getPathIndexes(nextPath, node.children, indexes);
    }

    static getPublishedNodes(nodes: TreeDataNode[], parentPath: string[] = []): string[][] {
        return nodes.reduce((publishedNodes: string[][], node: TreeDataNode) => {
            const { children, id, isPublished } = node;
            const nodePath: string[] = [...parentPath, id];
            const publishedPaths: string[][] = [];

            if (isPublished) {
                publishedPaths.push(nodePath);
            }
            if (children?.length) {
                publishedPaths.push(...TreeData.getPublishedNodes(children, nodePath));
            }

            publishedNodes.push(...publishedPaths);

            return publishedNodes;
        }, [] as string[][]);
    }
}

export const getKeywordsTreesData = (keywordsTrees: TreeWithId[]): Record<string, TreeData> => {
    return keywordsTrees.reduce<{ [key: string]: TreeData }>((acc, tree) => {
        acc[tree.id] = new TreeData(tree.content, tree.title, tree.theme);

        return acc;
    }, {});
};
