// eslint-disable-next-line import/no-duplicates, import/no-extraneous-dependencies
import format from 'date-fns/format';
// eslint-disable-next-line import/no-duplicates, import/no-extraneous-dependencies
import enGB from 'date-fns/locale/en-GB';
// eslint-disable-next-line import/no-duplicates, import/no-extraneous-dependencies
import fr from 'date-fns/locale/fr';

const ACCENTED_CITIES = [
    'alençon',
    'alès',
    'angoulême',
    'asnières-sur-seine',
    'besançon',
    'béthune',
    'béziers',
    'cayenne à st laurent du maroni',
    'chambéry',
    'charleville-mézières',
    'châlons-en-champagne',
    'châteaudun',
    'châteauroux',
    'châtellerault',
    'compiègne',
    'créteil',
    'la polynésie française',
    'fougères',
    'fréjus',
    'guéret',
    'la flèche',
    'la réunion',
    'lille métropole',
    'lunéville',
    'montbéliard',
    'montluçon',
    'montélimar',
    'mâcon',
    'nîmes',
    'nouméa',
    'nouvelle calédonie',
    'orléans',
    'pointe-à-pitre',
    'puy-en-vélay',
    'périgueux',
    'péronne',
    'romans-sur-isère',
    'saint-dié',
    'saint-dié-des-vosges',
    'saint-maur-des-fossés',
    'saint-étienne',
    'sarlat-la-canéda',
    'sète',
    'sélestat',
    'trévoux',
    'uzès',
    'épernay',
    'épinal',
    'évreux',
    'évry',
    'évry / corbeil-essonne',
];

const formatCity = (city: string) => {
    if (city.length === 0) return '';
    let c = city
        .toLowerCase()
        .replace(/^de|d'|of/, '')
        .trim();

    // accents
    for (let i = 0, len = ACCENTED_CITIES.length; i < len; i++) {
        if (c === ACCENTED_CITIES[i].normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
            c = ACCENTED_CITIES[i];
            break;
        }
    }
    // capitals
    let arr = [] as string[];
    const splitChars = ["'", ' ', '-'];
    splitChars.forEach((char) => {
        if (c.includes(char)) {
            arr = c.split(char);
            for (let i = 0; i < arr.length; i++) {
                if (
                    Boolean(arr[i].length) &&
                    !['de', 'en', 'sur', 'in', 'la', 'a', 'des'].includes(arr[i])
                ) {
                    arr[i] = arr[i][0].toUpperCase() + arr[i].slice(1);
                }
            }
            c = arr.join(char);
        }
    });

    return c[0].toUpperCase() + c.slice(1);
};

const formatJuridictionRoom = (juridictionRoom: string | undefined) => {
    if (!juridictionRoom || juridictionRoom.trim().length === 0) return '';
    let jr = juridictionRoom
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    jr = jr
        .trim()
        .replace(/pole|pôle/, 'Pôle')
        .replace(/chambres/gi, 'ch.')
        .replace(/chambre|chammbre/gi, 'ch.')
        .replace(/commer\w*/gi, 'com.')
        .replace(/crim\w*/gi, 'crim.')
        .replace(/civ\w*/gi, 'civ.')
        .replace(/corr\w*/gi, 'corr.')
        .replace(/soc\w*/gi, 'soc.')
        .replace(/grand\w*/gi, 'gr.')
        .replace(/sect\w*/gi, 'sect.');

    // sect. A -> A
    if (/sect. \D\b/.test(jr)) {
        const j = jr.indexOf('sect. ') + 'sect. '.length;
        jr = jr.substr(0, j) + jr[j].toUpperCase() + jr.substr(j + 1);
        jr = jr.replace('sect. ', '');
    }

    // 2eme sect. -> sect. 2
    const r = /[0-9]+\s?(e|re|ere|eme|th) sect./i;
    const m = jr.match(r);
    if (m && !!m.length) {
        const n = m[0].replace(/\D/g, '');
        jr = jr.replace(m[0], `sect. ${n}`);
    }

    return `, ${jr}`;
};

const addNumberToTitle = (title: string, number: string, language: string) =>
    `${title}${number ? `, ${'FR' === language ? 'n°' : 'No'}\xa0${number}` : ''}`;

const finalFormatting = (title: string, language: string, number: string) => {
    let t = title; // some manual corrections
    if (language === 'FR') {
        t = t
            .replace('pole', 'pôle')
            .replace('pleniere', 'plénière')
            .replace('assemblee', 'assemblée')
            .replace('refere', 'référé')
            .replace('defere', 'déférés')
            .replace('president', 'président')
            .replace('elargie', 'élargie')
            .replace('reuni', 'réuni')
            .replace('liberte', 'liberté')
            .replace('economique', 'économique')
            .replace('financiere', 'financière')
            .replace('premiere sect.', 'sect. 1')
            .replace('premiere', '1re')
            .replace('troisieme', '3e')
            .replace('cinquieme', '5e')
            .replace('corerctionnelle', 'corr.');

        // special cass format
        t = t
            .replace(/Cass\.,( 1re| 2e| 3e)? ch\./, 'Cass.$1')
            .replace(/Cass\., assemblée plénière/, 'Cass. ass. plén.')
            .replace(/Cass\. des requetes/, 'Cass. req.')
            .replace(/Cass\. réunies/, 'Cass. réun.');
    }
    if (language === 'EN') {
        t = t.replace('ch.', 'chamber').replace(/(\d)r?e/g, '$1th');
    }

    return addNumberToTitle(t, number, language);
};

const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

const formatDecisionTitle = (
    language: string,
    number: string,
    abbrev?: string | string[],
    juridictionName?: string,
    juridictionRoom?: string,
    city?: string,
    performedAt?: Date,
) => {
    let title = '';
    if (abbrev) {
        if (Array.isArray(abbrev)) {
            title += abbrev[language === 'FR' ? 0 : 1];
        } else title += abbrev;
    }
    if (title === '') {
        title += juridictionName || language === 'FR' ? 'Décision' : 'Decision';
    }
    if (city) {
        title += ` ${formatCity(city)}`;
    }
    title += formatJuridictionRoom(juridictionRoom);

    if (performedAt && isValidDate(performedAt)) {
        const dateFormat = language.toLowerCase() === 'fr' ? 'd MMMM yyyy' : 'MMMM d, yyyy';
        title += `, ${format(performedAt, dateFormat, {
            locale: language.toLowerCase() === 'fr' ? fr : enGB,
        })}`;
    }

    return finalFormatting(title, language, number);
};

export default formatDecisionTitle;
