import { Timestamp } from '@firebase/firestore-types';

export * from './revisions';
export * from './savedSearches';

export interface Session {
    createdAt: Timestamp;
    sessionId: string;
}

export interface User {
    email: string;
    firstName: string;
    hubspotId?: string;
    jobTitle?: string;
    lastName: string;
    phoneNumber?: string;
    session: Session;
    stripeCustomerId?: string;
}
/** User data needed to create user account */
export type UserData = Pick<User, 'email' | 'firstName' | 'jobTitle' | 'lastName' | 'phoneNumber'>;

export type FormSource = 'from_signup_page' | 'from_freemium_modal' | 'from_freemium_paywall';
