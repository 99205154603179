import styled from '@emotion/styled';

// SVG usually found on https://undraw.co/
// with color : #be0c1d

const SVGPage = styled.div<{
    readonly shiftTop?: number;
}>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    & > :first-of-type {
        margin-top: ${({ shiftTop = 0 }) => shiftTop / 2 + 40}px;
    }
    & > svg {
        opacity: 0.5;
        margin-top: 30px;
        height: auto;
        max-width: 300px;
        width: 80vw;
    }
`;
export default SVGPage;
