import CloseIcon from '@mui/icons-material/Close';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line import/no-duplicates
import { setDefaultOptions } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { fr } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Anonymous from './Anonymous';
import Login from './Login';
import useTheme from './theme/default';
import { ErrorBoundary } from '500';
import { LoadingPage } from 'components/Loader';
import { AuthProvider } from 'services/authentification';
import { PreferencesProvider } from 'services/preferences';
import './i18n';

// Définir la locale par défaut
setDefaultOptions({ locale: fr });

const Main = React.lazy(() => import('./Main'));

const notistackRef = React.createRef<SnackbarProvider>();

const App: React.FC = () => {
    const [theme, themePreferences] = useTheme();

    return (
        <ErrorBoundary>
            <Router>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <PreferencesProvider value={{ themePreferences }}>
                            <CssBaseline />
                            <SnackbarProvider
                                ref={notistackRef}
                                action={(key) => (
                                    <CloseIcon
                                        fontSize="small"
                                        onClick={() =>
                                            notistackRef?.current &&
                                            notistackRef.current.closeSnackbar(key)
                                        }
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                                autoHideDuration={10000}
                            >
                                <Switch>
                                    <Route path="/login">
                                        <Login />
                                    </Route>
                                    <Route>
                                        <AuthProvider unauthorizedPageElement={<Anonymous />}>
                                            <Suspense fallback={<LoadingPage />}>
                                                <Main />
                                            </Suspense>
                                        </AuthProvider>
                                    </Route>
                                </Switch>
                            </SnackbarProvider>
                        </PreferencesProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Router>
        </ErrorBoundary>
    );
};

export default App;
